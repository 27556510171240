// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use "sass:map";
@use "@angular/material" as mat;
@use "themes" as themes;
@use "variables" as variables;
@use "components/button-theme" as button;

// import
@import "components/button";
@import "components/bottom-sheet";
@import "components/card";
@import "components/input";
@import "components/checkbox";
@import "components/chips";
@import "components/dialog";
@import "components/form-field";
@import "components/links";
@import "components/table";
@import "components/toolbar";
@import "components/tab";
@import "components/tooltips";
@import "plugins/hot-toast";
@import "plugins/google";
@import "layout/layout";
@import "typography/typography";

@import "utils/font";
@import "utils/spaces";
@import "utils/processing";

@import "../lib/variables";
@import "migration";

@include mat.core();

// Light theme
$theme: themes.$light-theme;
$dark-theme: themes.$dark-theme;

html {
  --unit: 8px;

  color-scheme: dark;

  /**
   * Apply by default the dark theme for the whole app and the background color
   */
  background-color: var(--mat-app-background-color);
  color: var(--mat-app-text-color);

  // Sass mixins that includes styles for all components in the Mat library
  @include mat.all-component-themes($dark-theme);
  @include mat.color-variants-backwards-compatibility($dark-theme);
  @include mat.system-level-colors($dark-theme);
  @include mat.system-level-typography($dark-theme);
  @include mat.typography-hierarchy($dark-theme);
  /**
   * Override material theme tokens directly
   */
  --sys-background: #121212;
  --sys-surface: #121212;
  --sys-primary: #ff7d26;
  --sys-on-primary: #ffffff;
  --sys-surface-variant: #22292c;
  --sys-on-surface-variant: #6f8087;

  // Add custom components overrides
  @include button.button-size();

  // buttons
  --mdc-filled-button-container-shape: var(--fz-container-shape);
  --mdc-outlined-button-container-shape: var(--fz-container-shape);
  --mdc-protected-button-container-shape: var(--fz-container-shape);
  --mdc-text-button-container-shape: var(--fz-container-shape);
  --mat-standard-button-toggle-shape: var(--fz-container-shape);

  /**
   * Apply the light theme only when the user prefers light themes.
   */
  &[color-scheme="light"] {
    color-scheme: light;
    @include mat.all-component-colors($theme);
    @include mat.color-variants-backwards-compatibility($theme);
    @include mat.system-level-colors($theme);

    --sys-background: #fff;
    --sys-surface: #fff;
    --mdc-switch-unselected-handle-color: #889392;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.material-icons {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.primary-color {
  color: var(--primary-color);
}

.help-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 2px;
}

@import "reset";
