.cdk-overlay-pane .mat-bottom-sheet-container {
  padding: 16px 24px;
  border-radius: 16px 16px 0 0;
  --mat-bottom-sheet-container-background-color: var(--surface-elevation-modal);
  --mat-bottom-sheet-container-text-color: var(--text-primary);
  border: 1px solid #4f4f4f;

  &.mat-bottom-sheet-container-xlarge,
  &.mat-bottom-sheet-container-large,
  &.mat-bottom-sheet-container-medium {
    border-radius: 16px 16px 0 0;
    color: var(--text-primary);
    background-color: var(--surface-elevation-modal);
  }
}

//
// Override the default bottom sheet styles globally for game card bottom sheet
//
.game-card-bottom-sheet .mat-bottom-sheet-container {
  max-height: 100vh;
  max-width: max(100vw, 500px);
  min-width: min(100vw, 428px);
  padding: 0;
}

.game-card-bottom-sheet.cdk-overlay-pane .mat-bottom-sheet-container {
  padding: 0;
}

@media (min-width: 675px) {
  .cdk-overlay-pane .mat-bottom-sheet-container {
    padding: 24px 32px;
  }
}

@media (min-width: 960px) {
  .cdk-overlay-pane.referral-bonus-modal {
    max-width: 693px !important;
  }
}

//
// Add backdrop blur to bottom sheet
//
.cdk-overlay-container:has(.mat-bottom-sheet-container) .cdk-overlay-backdrop {
  backdrop-filter: blur(5px);
}
