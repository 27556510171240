.overlay-pane {
  margin: 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  color: var(--text-invert);
  border-radius: 6px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-field-default);
  background: var(--surface-elevation-6);
  box-shadow: 0px 4px 20px 0px rgba(18, 18, 18, 0.2);
}
[color-scheme='light'] .overlay-pane {
  color: var(--primary-text);
}
