/**
 * Fantaz material theme
 *
 * Color roles are like the "numbers" in a paint-by-number canvas. They're the
 * connective tissue between elements of the UI and what color goes where.
 */

@use "sass:map";
@use "@angular/material" as mat;
@use "variables" as variables;

$_palettes: (
  // ---------------------------------------------------------------------------
  // Use primary roles for the most prominent components across the UI, such as
  // the FAB, high-emphasis buttons, and active states.
  // ---------------------------------------------------------------------------
  primary:
    (
      //
      0: map.get(variables.$colors, neutral-black),
      // On primary fixed
      // 👉 md.sys.color.on-primary-fixed
      10: map.get(variables.$colors, neutral-black),
      20: map.get(variables.$colors, orange-500),
      25: map.get(variables.$colors, orange-500),
      // On primary container color
      // Contrast-passing color shown against the primary container
      // 👉 md.sys.color.on-primary-container
      // Note: On primary fixed variant -> md.sys.color.on-primary-fixed-variant
      30: map.get(variables.$colors, orange-500),
      35: map.get(variables.$colors, orange-500),
      // Primary color
      // Main color used across screens and components
      // 👉 -> md.sys.color.primary
      // Note: Surface tint -> md.sys.color.surface-tint
      // Note: Surface tint color -> md.sys.color.surface-tint-color
      40: map.get(variables.$colors, orange-500),
      50: map.get(variables.$colors, orange-400),
      60: map.get(variables.$colors, orange-300),
      70: map.get(variables.$colors, orange-300),
      // Inverse primary (dark)
      // 👉 md.sys.color.inverse-primary
      80: map.get(variables.$colors, orange-500),
      // Primary container color
      // Standout container color for key components
      // 👉 md.sys.color.primary-container
      // Note: Primary fixed  -> md.sys.color.primary-fixed
      90: map.get(variables.$colors, orange-200),
      95: map.get(variables.$colors, orange-100),
      98: map.get(variables.$colors, orange-100),
      99: map.get(variables.$colors, orange-100),
      // On primary color
      // Text and icons shown against the primary color
      // 👉 md.sys.color.on-primary
      100: map.get(variables.$colors, neutral-white)
    ),
  // ---------------------------------------------------------------------------
  // Use secondary roles for less prominent components in the UI such as filter
  // chips, toggles, and sliders.
  // ---------------------------------------------------------------------------
  secondary:
    (
      //
      0: map.get(variables.$colors, neutral-black),
      // On secondary fixed
      // 👉 md.sys.color.on-secondary-fixed
      10: map.get(variables.$colors, neutral-black),
      20: map.get(variables.$colors, blue-gray-500),
      25: map.get(variables.$colors, blue-gray-500),
      // On secondary container color
      // Contrast-passing color shown against the secondary container
      // 👉 md.sys.color.on-secondary-container
      // Note: On secondary fixed variant -> md.sys.color.on-secondary-fixed-variant
      30: map.get(variables.$colors, blue-gray-500),
      35: map.get(variables.$colors, blue-gray-500),
      // Secondary color
      // Accent color used across screens and components
      // 👉 md.sys.color.secondary
      40: map.get(variables.$colors, blue-gray-500),
      50: map.get(variables.$colors, blue-gray-400),
      60: map.get(variables.$colors, blue-gray-300),
      70: map.get(variables.$colors, blue-gray-300),
      // Primary fixed dim
      // 👉 md.sys.color.primary-fixed-dim
      // Note: Secondary fixed dim -> md.sys.color.secondary-fixed-dim
      80: map.get(variables.$colors, blue-gray-200),
      // Secondary container color
      // Less prominent container color for key components like tonal buttons
      // 👉 md.sys.color.secondary-container
      // Note: Secondary fixed -> md.sys.color.secondary-fixed
      90: map.get(variables.$colors, blue-gray-200),
      95: map.get(variables.$colors, blue-gray-100),
      98: map.get(variables.$colors, blue-gray-100),
      99: map.get(variables.$colors, neutral-white),
      // On secondary color
      // Text and icons shown against the secondary color
      // 👉 md.sys.color.on-secondary
      100: map.get(variables.$colors, neutral-white)
    ),
  // ---------------------------------------------------------------------------
  // Use tertiary roles for contrasting accents that balance primary and
  // secondary colors or bring heightened attention to an element such as an
  // input field.
  // ---------------------------------------------------------------------------
  tertiary:
    (
      0: map.get(variables.$colors, neutral-black),
      10: map.get(variables.$colors, neutral-black),
      20: map.get(variables.$colors, orange-500),
      25: map.get(variables.$colors, orange-500),
      // On tertiary container
      // Contrast-passing color shown against the tertiary container
      // 👉 md.sys.color.on-tertiary-container
      30: map.get(variables.$colors, orange-500),
      35: map.get(variables.$colors, orange-500),
      // Tertiary
      // 👉 md.sys.color.tertiary
      40: map.get(variables.$colors, gray-400),
      50: map.get(variables.$colors, orange-400),
      60: map.get(variables.$colors, orange-300),
      70: map.get(variables.$colors, orange-300),
      80: map.get(variables.$colors, gray-300),
      // Tertiary container
      // Contrasting container color, for components like input fields
      // 👉 md.sys.color.tertiary-container
      90: map.get(variables.$colors, gray-300),
      95: map.get(variables.$colors, orange-100),
      98: map.get(variables.$colors, orange-100),
      99: map.get(variables.$colors, neutral-white),
      // On tertiary color
      // 👉 md.sys.color.on-tertiary
      100: map.get(variables.$colors, neutral-white),
    ),
  // ---------------------------------------------------------------------------
  // Use neutral roles for the most subtle colors, such as a border or a
  // disabled button.
  // ---------------------------------------------------------------------------
  neutral:
    (
      0: map.get(variables.$colors, neutral-black),
      4: map.get(variables.$colors, neutral-black),
      6: map.get(variables.$colors, neutral-black),
      // On surface
      // Text and icons shown against the surface color
      // 👉 md.sys.color.on-surface
      10: map.get(variables.$colors, neutral-black),
      12: map.get(variables.$colors, neutral-black),
      17: map.get(variables.$colors, neutral-black),
      // Inverse surface
      // Displays opposite color of the surrounding UI
      // 👉 md.sys.color.inverse-surface
      20: map.get(variables.$colors, gray-500),
      22: map.get(variables.$colors, gray-500),
      24: map.get(variables.$colors, gray-500),
      25: map.get(variables.$colors, gray-500),
      // On surface variant
      // For text and icons to indicate active or inactive component state
      // 👉 md.sys.color.on-surface-variant
      30: map.get(variables.$colors, gray-500),
      35: map.get(variables.$colors, gray-500),
      40: map.get(variables.$colors, gray-400),
      50: map.get(variables.$colors, gray-400),
      60: map.get(variables.$colors, gray-300),
      70: map.get(variables.$colors, gray-300),
      80: map.get(variables.$colors, gray-200),
      // Surface Container Highest
      // Alternate surface color, can be used for active states
      // 👉 md.sys.color.surface-variant
      90: map.get(variables.$colors, gray-200),
      // Surface Container High
      // 👉 md.sys.color.surface-container-high
      92: map.get(variables.$colors, gray-100),
      // Surface Container
      // 👉 md.sys.color.surface-container
      94: map.get(variables.$colors, neutral-white),
      // Inverse on surface
      // Used for text and icons shown against the inverse surface color
      // 👉 md.sys.color.inverse-on-surface
      95: map.get(variables.$colors, gray-100),
      // Surface Container Low
      // 👉 md.sys.color.surface-container-low
      96: map.get(variables.$colors, gray-100),
      // Surface
      // Surface color for components like cards, sheets, and menus
      // 👉 md.sys.color.surface
      98: map.get(variables.$colors, gray-100),
      99: map.get(variables.$colors, neutral-white),
      // Surface Container Lowest
      // 👉 md.sys.color.surface-container-lowest
      100: map.get(variables.$colors, neutral-white),
    ),
  // ---------------------------------------------------------------------------
  // Use neutral-variant roles for the most subtle colors, such as a border or a
  // disabled button.
  // ---------------------------------------------------------------------------
  neutral-variant:
    (
      0: map.get(variables.$colors, neutral-black),
      10: map.get(variables.$colors, neutral-black),
      20: map.get(variables.$colors, gray-500),
      25: map.get(variables.$colors, gray-500),
      // On surface variant
      // For text and icons to indicate active or inactive component state
      // 👉 md.sys.color.on-surface-variant
      30: map.get(variables.$colors, gray-500),
      35: map.get(variables.$colors, gray-500),
      40: map.get(variables.$colors, gray-400),
      // Outline
      // Subtle color used for boundaries
      // 👉 md.sys.color.outline
      50: map.get(variables.$colors, gray-200),
      60: map.get(variables.$colors, blue-gray-400),
      70: map.get(variables.$colors, gray-300),
      // Outline Variant
      // Outline-variant is used to define the border of a component where 3:1
      // contrast ratio isn’t required, a container, or a divider.
      // 👉 md.sys.color.outline-variant
      80: map.get(variables.$colors, gray-200),
      // Surface Variant
      // Alternate surface color, can be used for active states
      // 👉 md.sys.color.surface-variant
      90: map.get(variables.$colors, gray-200),
      95: map.get(variables.$colors, gray-100),
      98: map.get(variables.$colors, gray-100),
      99: map.get(variables.$colors, neutral-white),
      100: map.get(variables.$colors, neutral-white),
    ),
  // ---------------------------------------------------------------------------
  // Use error roles to communicate error states, such as an incorrect password
  // entered into a text field.
  // ---------------------------------------------------------------------------
  error:
    (
      0: map.get(variables.$colors, neutral-black),
      10: map.get(variables.$colors, neutral-black),
      20: map.get(variables.$colors, status-red-100),
      25: map.get(variables.$colors, status-red-100),
      // On error container
      // md.sys.color.on-error-container
      30: map.get(variables.$colors, status-red-100),
      35: map.get(variables.$colors, status-red-100),
      // Error
      // Indicates errors, such as invalid input in a date picker
      // md.sys.color.error
      40: map.get(variables.$colors, status-red-90),
      50: map.get(variables.$colors, status-red-90),
      60: map.get(variables.$colors, status-red-90),
      70: map.get(variables.$colors, status-red-90),
      80: map.get(variables.$colors, status-red-30),
      // Error container
      // md.sys.color.error-container
      90: map.get(variables.$colors, status-red-30),
      95: map.get(variables.$colors, status-red-30),
      98: map.get(variables.$colors, status-red-30),
      99: map.get(variables.$colors, neutral-white),
      // On error color
      // Used for text and icons on the error color
      // md.sys.color.on-error
      100: map.get(variables.$colors, neutral-white),
    ),
  extended: (
    custom-color-1: map.get(variables.$colors, vip-gold-400),
    custom-color-1-container: map.get(variables.$colors, vip-gold-100),
    on-custom-color-1: map.get(variables.$colors, neutral-white),
    on-custom-color-1-container: map.get(variables.$colors, neutral-black),
  )
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
      use-system-variables: true,
    ),
    typography: (
      brand-family: "Roboto",
      bold-weight: 400,
      use-system-variables: true,
    ),
    density: (
      scale: 0,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
      use-system-variables: true,
    ),
    typography: (
      brand-family: "Roboto",
      bold-weight: 400,
      use-system-variables: true,
    ),
    density: (
      scale: 0,
    ),
  )
);

:root {
  --unit: 8px;
  --fz-container-shape: 12px;
}
