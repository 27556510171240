$border-radius: 16px;

.mat-mdc-table {
  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }
  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    padding: 0 8px;
  }
}
/**
 * Responsive Table
 */
@media (max-width: 768px) {
  .mat-mdc-table[fzResponsiveTable] {
    box-shadow: none;

    thead {
      display: none;
    }

    .mat-mdc-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 2;

      th.mat-mdc-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-around;
        padding: 1rem 5px 1rem 0.5rem;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-mdc-row {
      height: auto;
      background: #ffffff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      margin-bottom: 24px;
      display: block;

      td.mat-cell:not(.mat-column-actions) {
        min-height: 49px;
        display: flex;
        padding: 0 0.5rem;
        align-items: center;
        justify-content: space-between;

        label {
          text-align: end;
        }

        &:before {
          content: attr(data-column-name);
          font-weight: 500;
        }
      }

      td.mat-column-actions {
        min-height: 49px;
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;

        & > button,
        & > * {
          margin: 0.5rem 0;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      td {
        & > a,
        & > span,
        & > div,
        & > p {
          text-align: right;
        }

        & > a,
        & > span,
        & > div:not(.large-content),
        & > p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 24px;
        }

        & > .large-content {
          width: 50%;
        }
      }
    }
  }

  .mat-mdc-card,
  .mat-expansion-panel {
    .mat-mdc-table[fzResponsiveTable] {
      border: 0.5rem solid var(--border-elevation-1);
    }
  }
}

.table-wrapper {
  border-radius: $border-radius;
  background: var(--surface-elevation-1);
  box-shadow: 0px 4px 8px 0px var(--color-box-shadow);

  .mat-mdc-table,
  .mat-mdc-paginator {
    background: var(--surface-elevation-1);
    color: var(--text-primary);
    border-radius: $border-radius;
    border: 1px solid var(--border-elevation-1);
    --mdc-icon-button-icon-color: var(--icon-primary);
    --mat-paginator-enabled-icon-color: var(--icon-primary);
    --mat-paginator-disabled-icon-color: var(--icon-limit);
  }

  .mat-mdc-select-arrow {
    color: var(--icon-primary);
  }

  .mat-mdc-select-value,
  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    color: var(--text-primary);
  }

  .mdc-data-table__cell {
    border-bottom-color: var(--border-elevation-1);
  }

  .mat-mdc-table .mat-mdc-header-cell:first-child {
    border-top-left-radius: $border-radius;
  }

  .mat-mdc-table .mat-mdc-header-cell:last-child {
    border-top-right-radius: $border-radius;
  }

  .mdc-data-table__cell:first-child > .mdc-data-table__cell {
    border-bottom: unset;
    border-bottom-left-radius: $border-radius;
  }

  .mdc-data-table__row:last-child > .mdc-data-table__cell:last-child {
    border-bottom-right-radius: $border-radius;
  }

  .mdc-data-table__row:last-child > .mdc-data-table__cell:first-child {
    border-bottom-left-radius: $border-radius;
  }

  .mat-mdc-paginator {
    border-radius: $border-radius;

    .mat-mdc-paginator-range-label,
    .mat-mdc-paginator-page-size-label {
      color: var(--text-tertiary);
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled),
    .mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
      background-color: transparent;
      border-color: var(--border-field-default);
    }
  }
}

.table-wrapper:has(> .mat-mdc-table ~ .mat-mdc-paginator) {
  .mat-mdc-table {
    border-bottom: none;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .mdc-data-table__cell:first-child > .mdc-data-table__cell {
    border-bottom: unset;
    border-radius: unset;
  }

  .mdc-data-table__row:last-child > .mdc-data-table__cell {
    border-radius: unset;
    border-bottom: 1px solid var(--border-elevation-1);
  }

  .mat-mdc-paginator {
    border-radius: unset;
    border-top: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

/**
 * Add table clickable styles
 */
table {
  &.table-clickable {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
          background: #fafafa;
        }
      }
    }
  }

  .mat-header-cell {
    color: #000;
  }
}

.mat-sort-header-content {
  text-align: left !important;
}
