// limit of margins and paddings
$limit: 5;

/**
 * Mixing to generate spacing utilities
 */
@mixin spacing-list {
  @for $i from 1 through $limit {
    // generate margin utilities
    .margin-left-#{$i},
    .ml--#{$i},
    html .mat-typography p.ml--#{$i},
    html .mat-typography .mat-headline-small.ml--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      margin-left: calc(var(--unit) * $i);
    }

    .margin-top-#{$i},
    .mt--#{$i},
    html .mat-typography p.mt--#{$i},
    html .mat-typography .mat-headline-small.mt--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      margin-top: calc(var(--unit) * $i);
    }

    .margin-right-#{$i},
    .mr--#{$i},
    html .mat-typography p.mr--#{$i},
    html .mat-typography .mat-headline-small.mr--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      margin-right: calc(var(--unit) * $i);
    }

    .margin-bottom-#{$i},
    .mb--#{$i},
    html .mat-typography p.mb--#{$i},
    html .mat-typography .mat-headline-small.mb--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      margin-bottom: calc(var(--unit) * $i);
    }

    // generate padding utilities
    .padding-left-#{$i}, // <- deprecated
    .pl--#{$i},
    html .mat-typography p.pl--#{$i},
    html .mat-typography .mat-headline-small.pl--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      padding-left: calc(var(--unit) * $i);
    }

    .padding-top-#{$i},
    html .mat-typography p.pt--#{$i},
    html .mat-typography .mat-headline-small.pt--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      padding-top: calc(var(--unit) * $i);
    }

    .padding-right-#{$i},
    html .mat-typography p.pr--#{$i},
    html .mat-typography .mat-headline-small.pr--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      padding-right: calc(var(--unit) * $i);
    }

    .padding-bottom-#{$i},
    html .mat-typography p.pb--#{$i},
    html .mat-typography .mat-headline-small.pb--#{$i},
    html .mat-typography .mat-headline-large.ml--#{$i} {
      padding-bottom: calc(var(--unit) * $i);
    }

    .gap--#{$i} {
      gap: calc(var(--unit) * $i);
    }
  }
}

// generate spacing classes
@include spacing-list();

// width utilities
.full-width {
  width: 100%;
}

.d--block {
  display: block;
}
