@font-face {
  font-family: SteadfastOblique;
  src: url("/shared/fonts/SteadfastOblique.ttf");
}

.ff--steadfast {
  font-family: SteadfastOblique;
}

/**
 * Capitalize a string
 */
.capitalize {
  &:first-letter {
    text-transform: capitalize;
  }
}

.text-underline {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

// colors utils
@mixin font-colors($colors) {
  @each $name, $color in $colors {
    .font-color--#{$name} {
      color: $color;
    }
  }
}
@mixin font-weight($weights) {
  @each $name, $weight in $weights {
    html .mat-body-large,
    html .mat-typography .mat-body-large,
    html .mat-typography {
      &.fw--#{$name} {
        font-weight: $weight;
      }
    }
  }
}

$font-colors: (
  primary: var(--primary-color),
  orange: orange,
  green: green,
  tertiary: #9a9a9a,
);

$font-weights: (
  300: 300,
  400: 400,
  500: 500,
  700: 700,
);

@include font-colors($font-colors);
@include font-weight($font-weights);

.text-align {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.text-decoration--underline {
  text-decoration: underline;
}
