:root {
  --mdc-filled-button-container-shape: var(--fz-container-shape);
  --mdc-outlined-button-container-shape: var(--fz-container-shape);
  --mdc-protected-button-container-shape: var(--fz-container-shape);
  --mdc-text-button-container-shape: var(--fz-container-shape);

  // disabled button
  --mdc-filled-button-disabled-container-color: grey;

  // button toggle
  --mat-standard-button-toggle-shape: var(--fz-container-shape);
}

// Override buttons styles to apply fantaz guideline
// by default using mat-flat-button
// https://www.figma.com/design/v3w1uD8EccjA8Gm9Bwoivp/%5BTEST%5D-FantaZ-Design-System?node-id=171-448&m=dev
// Filled   -> mat-flat-button
// Outlined -> mat-stroked-button
// Text     -> mat-button

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  text-decoration: none;
  white-space: nowrap;

  .mdc-button__label {
    span {
      margin: 0;
    }
  }
}

.mdc-button {
  text-transform: uppercase !important;
}

.mat-mdc-unelevated-button {
  &:not(:disabled) {
    border: 0px solid #f39115;
    background: linear-gradient(184deg, #f39115 0%, #f04f1b 101.01%);
  }

  &:disabled {
    border: 0px solid #f39115;
    background: var(--button-disabled-fill, #f5f5f5);
  }
}

// Outlined buttons
.mat-mdc-outlined-button:not(:disabled) {
}

button.mat-mdc-raised-button.mat-primary,
button.mat-fab.mat-primary,
button.mat-mini-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  text-transform: uppercase;
  background: var(--gradients-primary-orange-gradient-buttons);
  --mdc-filled-button-label-text-color: var(--color-neutral-white);
  --mdc-protected-button-label-text-color: var(--color-neutral-white);
}

.mat-mdc-outlined-button.mdc-button--outlined {
  text-transform: uppercase;
  background: transparent;
}

.mat-mdc-unelevated-button.mat-primary:disabled,
.mat-mdc-unelevated-button.mat-accent:disabled,
.mat-mdc-unelevated-button.mat-warn:disabled,
.mat-mdc-unelevated-button:disabled,
.mat-mdc-raised-button.mat-primary:disabled,
.mat-mdc-raised-button.mat-accent:disabled,
.mat-mdc-raised-button.mat-warn:disabled,
.mat-mdc-raised-button:disabled,
.mat-fab.mat-primary:disabled,
.mat-fab.mat-accent:disabled,
.mat-fab.mat-warn:disabled,
.mat-fab:disabled,
.mat-mini-fab.mat-primary:disabled,
.mat-mini-fab.mat-accent:disabled,
.mat-mini-fab.mat-warn:disabled,
.mat-mini-fab:disabled {
  color: var(--text-inactive);
  background: var(--surface-inactive);
}

.mat-mdc-icon-button:not(:disabled) {
  color: inherit;
}

// resolve disabled button tooltip
button.mdc-button:disabled {
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}
