@use "sass:map" as map;

$sizes: (
  small: (
    height: 36px,
  ),
  medium: (
    height: 48px,
  ),
  large: (
    height: 56px,
  ),
);

@mixin button-size() {
  @each $size, $value in $sizes {
    .fz-button--#{$size} {
      --mdc-outlined-button-container-height: #{map.get($value, height)};
      --mdc-filled-button-container-height: #{map.get($value, height)};
      --mdc-raised-button-container-height: #{map.get($value, height)};
      --mdc-text-button-container-height: #{map.get($value, height)};
    }
  }
}
