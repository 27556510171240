mat-tab-group {
  margin-bottom: calc(var(--unit) * 3);
}

.mdc-tab-indicator--active {
  .mdc-tab-indicator {
    background: linear-gradient(
      180deg,
      rgba(255, 125, 38, 0) 0%,
      rgba(255, 125, 38, 0.1) 100%
    );
  }
}
