/**
* Fantaz variables
*
* This SCSS file sets up the global fonts and color variables for the Fantaz
* project. It ensures a consistent look and feel across the entire application
* by defining the primary, secondary, and accent colors, as well as the main
* typography settings.
*/

// Variables:
// The main color used throughout the application for primary actions and highlights.
$colors: (
  // Blue Gray
  blue-gray-100: #6f8087,
  blue-gray-200: #455a64,
  blue-gray-300: #37474f,
  blue-gray-400: #263238,
  blue-gray-500: #22292c,

  // Gray
  gray-100: #f5f5f5,
  gray-200: #dddddd,
  gray-300: #c4c4c4,
  gray-400: #9a9a9a,
  gray-500: #4f4f4f,

  // Neutral
  neutral-black: #000000,
  neutral-black-8: #00000014,
  neutral-black-12: #0000001f,
  neutral-black-16: #00000029,
  neutral-black-bg: #121212,
  neutral-white: #ffffff,
  neutral-white-12: #ffffff1f,
  neutral-white-16: #ffffff29,
  neutral-white-8: #ffffff14,

  // Orange
  orange-100: #fef5ed,
  orange-200: #ffd7bc,
  orange-300: #ffb88a,
  orange-400: #ff9b58,
  orange-500: #ff7d26,
  orange-505: #ff7d260d,
  orange-508: #ff7d2614,
  orange-511: #ff7d261c,
  orange-512: #ff7d261f,
  orange-514: #ff7d2624,
  orange-515: #ff7d2626,
  orange-516: #ff7d2629,
  orange-530: #ff7d264d,

  // Status
  status-blue: #30a3e6,
  status-gray: #9a9a9a,
  status-green: #27ae60,
  status-red-100: #800000,
  status-red-30: #ffb2b2,
  status-red-90: #ff1a1a,
  status-yellow: #ffcc5a,

  // Vip Gold
  vip-gold-100: #eddfc2,
  vip-gold-200: #dbc9a6,
  vip-gold-300: #d2b478,
  vip-gold-400: #b48e44
);

// Usage:
// Import this file at the beginning of your main SCSS file to apply the global styles.
// Use the defined variables throughout your SCSS files to maintain consistency in colors and typography.
