mat-form-field:has(mat-error),
mat-form-field:has(mat-hint) {
  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    position: relative;
    margin-top: 4px;
    margin-bottom: 12px;
  }
}
