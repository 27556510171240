:root {
  --toolbar-height: 56px;
  --mat-toolbar-standard-height: 56px;
}

@media (min-width: 768px) {
  :root {
    --mat-toolbar-standard-height: 80px;
    --toolbar-height: 80px;
  }
}
