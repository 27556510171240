/**
 * Fantaz material theme
 *
 * Color roles are like the "numbers" in a paint-by-number canvas. They're the
 * connective tissue between elements of the UI and what color goes where.
 */
/**
* Fantaz variables
*
* This SCSS file sets up the global fonts and color variables for the Fantaz
* project. It ensures a consistent look and feel across the entire application
* by defining the primary, secondary, and accent colors, as well as the main
* typography settings.
*/
:root {
  --unit: 8px;
  --fz-container-shape: 12px;
}

:root {
  --mdc-filled-button-container-shape: var(--fz-container-shape);
  --mdc-outlined-button-container-shape: var(--fz-container-shape);
  --mdc-protected-button-container-shape: var(--fz-container-shape);
  --mdc-text-button-container-shape: var(--fz-container-shape);
  --mdc-filled-button-disabled-container-color: grey;
  --mat-standard-button-toggle-shape: var(--fz-container-shape);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  text-decoration: none;
  white-space: nowrap;
}
.mat-mdc-button.mat-mdc-button-base .mdc-button__label span,
.mat-mdc-raised-button.mat-mdc-button-base .mdc-button__label span,
.mat-mdc-unelevated-button.mat-mdc-button-base .mdc-button__label span,
.mat-mdc-outlined-button.mat-mdc-button-base .mdc-button__label span {
  margin: 0;
}

.mdc-button {
  text-transform: uppercase !important;
}

.mat-mdc-unelevated-button:not(:disabled) {
  border: 0px solid #f39115;
  background: linear-gradient(184deg, #f39115 0%, #f04f1b 101.01%);
}
.mat-mdc-unelevated-button:disabled {
  border: 0px solid #f39115;
  background: var(--button-disabled-fill, #f5f5f5);
}

button.mat-mdc-raised-button.mat-primary,
button.mat-fab.mat-primary,
button.mat-mini-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  text-transform: uppercase;
  background: var(--gradients-primary-orange-gradient-buttons);
  --mdc-filled-button-label-text-color: var(--color-neutral-white);
  --mdc-protected-button-label-text-color: var(--color-neutral-white);
}

.mat-mdc-outlined-button.mdc-button--outlined {
  text-transform: uppercase;
  background: transparent;
}

.mat-mdc-unelevated-button.mat-primary:disabled,
.mat-mdc-unelevated-button.mat-accent:disabled,
.mat-mdc-unelevated-button.mat-warn:disabled,
.mat-mdc-unelevated-button:disabled,
.mat-mdc-raised-button.mat-primary:disabled,
.mat-mdc-raised-button.mat-accent:disabled,
.mat-mdc-raised-button.mat-warn:disabled,
.mat-mdc-raised-button:disabled,
.mat-fab.mat-primary:disabled,
.mat-fab.mat-accent:disabled,
.mat-fab.mat-warn:disabled,
.mat-fab:disabled,
.mat-mini-fab.mat-primary:disabled,
.mat-mini-fab.mat-accent:disabled,
.mat-mini-fab.mat-warn:disabled,
.mat-mini-fab:disabled {
  color: var(--text-inactive);
  background: var(--surface-inactive);
}

.mat-mdc-icon-button:not(:disabled) {
  color: inherit;
}

button.mdc-button:disabled {
  pointer-events: auto;
}
button.mdc-button:disabled:hover {
  cursor: pointer;
}

.cdk-overlay-pane .mat-bottom-sheet-container {
  padding: 16px 24px;
  border-radius: 16px 16px 0 0;
  --mat-bottom-sheet-container-background-color: var(--surface-elevation-modal);
  --mat-bottom-sheet-container-text-color: var(--text-primary);
  border: 1px solid #4f4f4f;
}
.cdk-overlay-pane .mat-bottom-sheet-container.mat-bottom-sheet-container-xlarge, .cdk-overlay-pane .mat-bottom-sheet-container.mat-bottom-sheet-container-large, .cdk-overlay-pane .mat-bottom-sheet-container.mat-bottom-sheet-container-medium {
  border-radius: 16px 16px 0 0;
  color: var(--text-primary);
  background-color: var(--surface-elevation-modal);
}

.game-card-bottom-sheet .mat-bottom-sheet-container {
  max-height: 100vh;
  max-width: max(100vw, 500px);
  min-width: min(100vw, 428px);
  padding: 0;
}

.game-card-bottom-sheet.cdk-overlay-pane .mat-bottom-sheet-container {
  padding: 0;
}

@media (min-width: 675px) {
  .cdk-overlay-pane .mat-bottom-sheet-container {
    padding: 24px 32px;
  }
}
@media (min-width: 960px) {
  .cdk-overlay-pane.referral-bonus-modal {
    max-width: 693px !important;
  }
}
.cdk-overlay-container:has(.mat-bottom-sheet-container) .cdk-overlay-backdrop {
  backdrop-filter: blur(5px);
}

mat-card.mat-mdc-card {
  padding: 8px;
}
mat-card.mat-mdc-card .mat-mdc-card-actions {
  padding: 8px 16px 16px 8px;
  min-height: 64px;
}

mat-form-field:has(mat-error) .mat-mdc-form-field-bottom-align::before,
mat-form-field:has(mat-hint) .mat-mdc-form-field-bottom-align::before {
  display: none;
}
mat-form-field:has(mat-error) .mat-mdc-form-field-hint-wrapper,
mat-form-field:has(mat-error) .mat-mdc-form-field-error-wrapper,
mat-form-field:has(mat-hint) .mat-mdc-form-field-hint-wrapper,
mat-form-field:has(mat-hint) .mat-mdc-form-field-error-wrapper {
  position: relative;
  margin-top: 4px;
  margin-bottom: 12px;
}

.mat-mdc-menu-panel.mdc-menu-surface {
  will-change: unset;
}

.mat-mdc-menu-item:not([disabled]):hover {
  --mat-menu-item-hover-state-layer-color: var(--surface-elevation-2);
}

body .cdk-overlay-container {
  z-index: 100000;
}

.cdk-overlay-container,
mat-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 24px;
  --mdc-dialog-supporting-text-color: var(--text-primary);
  --mdc-dialog-subhead-color: var(--text-primary);
}

.mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface {
  padding: 24px 32px;
  --mdc-dialog-container-elevation: 0px 4px 8px 0px var(--color-box-shadow);
  --mdc-dialog-container-color: var(--surface-elevation-modal);
  max-width: 575px;
}
.mat-mdc-dialog-container .mdc-dialog__actions {
  padding: 0;
}
.mat-mdc-dialog-container.mdc-dialog .mdc-dialog__content {
  padding: 0;
}

.mat-mdc-dialog-surface.mdc-dialog__surface p {
  padding: 0;
  text-align: center;
  color: var(--text-primary);
}

.mat-mdc-dialog-title.fz-dialog__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--icon-primary);
  padding: 0;
  z-index: 1000;
}
.mat-mdc-dialog-title.fz-dialog__close::before {
  display: none;
}

@media (min-width: 768px) {
  .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface {
    padding: 40px 64px;
  }
}
.mat-mdc-form-field-infix {
  width: 100% !important;
}

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  margin: 0;
}
.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 42px;
  align-items: center;
}
.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  color: var(--icon-limit);
  margin-right: 16px;
}
.mat-mdc-form-field .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  margin-left: 4px;
}

.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field {
  width: 100%;
}

a {
  text-decoration: none;
}
a[href] {
  color: var(--sys-primary);
}
a.link {
  text-decoration: underline;
  color: var(--sys-primary);
}

.mat-mdc-table th.mat-mdc-header-cell:first-of-type,
.mat-mdc-table td.mat-mdc-cell:first-of-type,
.mat-mdc-table td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px;
}
.mat-mdc-table .mdc-data-table__header-cell,
.mat-mdc-table .mdc-data-table__cell {
  padding: 0 8px;
}

/**
 * Responsive Table
 */
@media (max-width: 768px) {
  .mat-mdc-table[fzResponsiveTable] {
    box-shadow: none;
  }
  .mat-mdc-table[fzResponsiveTable] thead {
    display: none;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row th.mat-mdc-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 5px 1rem 0.5rem;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row th.mat-mdc-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row th.mat-mdc-header-cell:not([mat-sort-header]), .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row th.mat-mdc-header-cell.mat-sort-header-disabled, .mat-mdc-table[fzResponsiveTable] .mat-mdc-header-row th.mat-mdc-header-cell.mat-column-actions {
    display: none;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row {
    height: auto;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: 24px;
    display: block;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-cell:not(.mat-column-actions) {
    min-height: 49px;
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-cell:not(.mat-column-actions) label {
    text-align: end;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    font-weight: 500;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-column-actions {
    min-height: 49px;
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-column-actions > button, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td.mat-column-actions > * {
    margin: 0.5rem 0;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > a, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > span, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > div, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > p {
    text-align: right;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > a, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > span, .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > div:not(.large-content), .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 24px;
  }
  .mat-mdc-table[fzResponsiveTable] .mat-mdc-row td > .large-content {
    width: 50%;
  }
  .mat-mdc-card .mat-mdc-table[fzResponsiveTable],
  .mat-expansion-panel .mat-mdc-table[fzResponsiveTable] {
    border: 0.5rem solid var(--border-elevation-1);
  }
}
.table-wrapper {
  border-radius: 16px;
  background: var(--surface-elevation-1);
  box-shadow: 0px 4px 8px 0px var(--color-box-shadow);
}
.table-wrapper .mat-mdc-table,
.table-wrapper .mat-mdc-paginator {
  background: var(--surface-elevation-1);
  color: var(--text-primary);
  border-radius: 16px;
  border: 1px solid var(--border-elevation-1);
  --mdc-icon-button-icon-color: var(--icon-primary);
  --mat-paginator-enabled-icon-color: var(--icon-primary);
  --mat-paginator-disabled-icon-color: var(--icon-limit);
}
.table-wrapper .mat-mdc-select-arrow {
  color: var(--icon-primary);
}
.table-wrapper .mat-mdc-select-value,
.table-wrapper .mdc-data-table__header-cell,
.table-wrapper .mdc-data-table__cell {
  color: var(--text-primary);
}
.table-wrapper .mdc-data-table__cell {
  border-bottom-color: var(--border-elevation-1);
}
.table-wrapper .mat-mdc-table .mat-mdc-header-cell:first-child {
  border-top-left-radius: 16px;
}
.table-wrapper .mat-mdc-table .mat-mdc-header-cell:last-child {
  border-top-right-radius: 16px;
}
.table-wrapper .mdc-data-table__cell:first-child > .mdc-data-table__cell {
  border-bottom: unset;
  border-bottom-left-radius: 16px;
}
.table-wrapper .mdc-data-table__row:last-child > .mdc-data-table__cell:last-child {
  border-bottom-right-radius: 16px;
}
.table-wrapper .mdc-data-table__row:last-child > .mdc-data-table__cell:first-child {
  border-bottom-left-radius: 16px;
}
.table-wrapper .mat-mdc-paginator {
  border-radius: 16px;
}
.table-wrapper .mat-mdc-paginator .mat-mdc-paginator-range-label,
.table-wrapper .mat-mdc-paginator .mat-mdc-paginator-page-size-label {
  color: var(--text-tertiary);
}
.table-wrapper .mat-mdc-paginator .mdc-text-field--filled:not(.mdc-text-field--disabled),
.table-wrapper .mat-mdc-paginator .mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
  background-color: transparent;
  border-color: var(--border-field-default);
}

.table-wrapper:has(> .mat-mdc-table ~ .mat-mdc-paginator) .mat-mdc-table {
  border-bottom: none;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.table-wrapper:has(> .mat-mdc-table ~ .mat-mdc-paginator) .mdc-data-table__cell:first-child > .mdc-data-table__cell {
  border-bottom: unset;
  border-radius: unset;
}
.table-wrapper:has(> .mat-mdc-table ~ .mat-mdc-paginator) .mdc-data-table__row:last-child > .mdc-data-table__cell {
  border-radius: unset;
  border-bottom: 1px solid var(--border-elevation-1);
}
.table-wrapper:has(> .mat-mdc-table ~ .mat-mdc-paginator) .mat-mdc-paginator {
  border-radius: unset;
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

/**
 * Add table clickable styles
 */
table.table-clickable tbody tr:hover {
  cursor: pointer;
  background: #fafafa;
}
table .mat-header-cell {
  color: #000;
}

.mat-sort-header-content {
  text-align: left !important;
}

:root {
  --toolbar-height: 56px;
  --mat-toolbar-standard-height: 56px;
}

@media (min-width: 768px) {
  :root {
    --mat-toolbar-standard-height: 80px;
    --toolbar-height: 80px;
  }
}
mat-tab-group {
  margin-bottom: calc(var(--unit) * 3);
}

.mdc-tab-indicator--active .mdc-tab-indicator {
  background: linear-gradient(180deg, rgba(255, 125, 38, 0) 0%, rgba(255, 125, 38, 0.1) 100%);
}

.overlay-pane {
  margin: 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  color: var(--text-invert);
  border-radius: 6px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--border-field-default);
  background: var(--surface-elevation-6);
  box-shadow: 0px 4px 20px 0px rgba(18, 18, 18, 0.2);
}

[color-scheme=light] .overlay-pane {
  color: var(--primary-text);
}

body .hot-toast-bar-base-container.hot-toast-theme-snackbar .hot-toast-bar-base {
  gap: 8px;
}

.pac-container {
  z-index: 9999999;
  border-radius: 8px;
  width: auto !important;
  box-shadow: 1px 0px 20px -10px rgba(0, 0, 0, 0.2901960784);
  border: none;
  outline: 1px solid var(--sys-outline);
}
.pac-container.position-fixed {
  position: fixed !important;
}
.pac-container .pac-item {
  padding: 12px 16px;
  text-overflow: initial;
  overflow: initial;
  white-space: initial;
  font: var(--sys-body-medium);
}
.pac-container .pac-item:hover {
  cursor: pointer;
}
.pac-container .pac-item .pac-icon {
  display: none;
}
.pac-container.pac-logo::after {
  display: none;
}

:root {
  --fz-container-margin: 16px;
}

.container,
.mat-toolbar-row.container {
  box-sizing: border-box;
  margin: 0px auto;
  width: calc(100% - 2 * var(--fz-container-margin));
}

.bottom-spacer {
  margin-bottom: 32px;
}

.wallet-view .details-column,
.wallet-view .principal-column {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  :root {
    --fz-container-margin: 48px;
  }
  .wallet-view .wallet-container {
    display: flex;
    flex-flow: row;
    place-content: space-between;
    gap: 32px;
    justify-content: center;
  }
  .wallet-view .details-column {
    flex: 1 1 34%;
    box-sizing: border-box;
    max-width: 34%;
    order: 2;
  }
  .wallet-view .details-column .big {
    flex: 1 1 48%;
    max-width: 48%;
  }
  .wallet-view .principal-column {
    flex: 1 1 66%;
    box-sizing: border-box;
    max-width: 66%;
    order: 1;
  }
  .wallet-view .principal-column .short {
    flex: 1 1 62%;
    max-width: 62%;
  }
}
@media (min-width: 1440px) {
  :root {
    --fz-container-margin: 0;
  }
  .container,
  .mat-toolbar-row.container {
    max-width: 1440px;
  }
}
@media (min-width: 1520px) {
  .container,
  .mat-toolbar-row.container {
    max-width: 100%;
    width: calc(100% - 208px);
    margin: auto;
  }
}
@media (min-width: 1920px) {
  .container,
  .mat-toolbar-row.container {
    max-width: 1920px;
    width: calc(100% - 208px);
    margin: auto;
  }
}
@media (max-width: 768px) {
  .hide--phone-device {
    display: none;
  }
}
.display--flex {
  display: flex;
}
.display--block {
  display: block;
}

.flex-direction--column {
  flex-direction: column;
}
.flex-direction--row {
  flex-direction: row;
}

.justify-content--center {
  justify-content: center;
}
.justify-content--space-between {
  justify-content: space-between;
}
.justify-content--space-around {
  justify-content: space-around;
}

.align-items--center {
  align-items: center;
}
.align-items--flex-start {
  align-items: flex-start;
}
.align-items--flex-end {
  align-items: flex-end;
}

/*
 * View Transitions
 */
@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}
@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}
::view-transition-old(root) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

@font-face {
  font-family: SteadfastOblique;
  src: url("/shared/fonts/SteadfastOblique.ttf");
}
.ff--steadfast {
  font-family: SteadfastOblique;
}

/**
 * Capitalize a string
 */
.capitalize:first-letter {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  cursor: pointer;
}

.font-color--primary {
  color: var(--primary-color);
}

.font-color--orange {
  color: orange;
}

.font-color--green {
  color: green;
}

.font-color--tertiary {
  color: #9a9a9a;
}

html .mat-body-large.fw--300,
html .mat-typography .mat-body-large.fw--300,
html .mat-typography.fw--300 {
  font-weight: 300;
}

html .mat-body-large.fw--400,
html .mat-typography .mat-body-large.fw--400,
html .mat-typography.fw--400 {
  font-weight: 400;
}

html .mat-body-large.fw--500,
html .mat-typography .mat-body-large.fw--500,
html .mat-typography.fw--500 {
  font-weight: 500;
}

html .mat-body-large.fw--700,
html .mat-typography .mat-body-large.fw--700,
html .mat-typography.fw--700 {
  font-weight: 700;
}

.text-align--left {
  text-align: left;
}
.text-align--center {
  text-align: center;
}
.text-align--right {
  text-align: right;
}

.text-decoration--underline {
  text-decoration: underline;
}

/**
 * Mixing to generate spacing utilities
 */
.margin-left-1,
.ml--1,
html .mat-typography p.ml--1,
html .mat-typography .mat-headline-small.ml--1,
html .mat-typography .mat-headline-large.ml--1 {
  margin-left: calc(var(--unit) * 1);
}

.margin-top-1,
.mt--1,
html .mat-typography p.mt--1,
html .mat-typography .mat-headline-small.mt--1,
html .mat-typography .mat-headline-large.ml--1 {
  margin-top: calc(var(--unit) * 1);
}

.margin-right-1,
.mr--1,
html .mat-typography p.mr--1,
html .mat-typography .mat-headline-small.mr--1,
html .mat-typography .mat-headline-large.ml--1 {
  margin-right: calc(var(--unit) * 1);
}

.margin-bottom-1,
.mb--1,
html .mat-typography p.mb--1,
html .mat-typography .mat-headline-small.mb--1,
html .mat-typography .mat-headline-large.ml--1 {
  margin-bottom: calc(var(--unit) * 1);
}

.padding-left-1,
.pl--1,
html .mat-typography p.pl--1,
html .mat-typography .mat-headline-small.pl--1,
html .mat-typography .mat-headline-large.ml--1 {
  padding-left: calc(var(--unit) * 1);
}

.padding-top-1,
html .mat-typography p.pt--1,
html .mat-typography .mat-headline-small.pt--1,
html .mat-typography .mat-headline-large.ml--1 {
  padding-top: calc(var(--unit) * 1);
}

.padding-right-1,
html .mat-typography p.pr--1,
html .mat-typography .mat-headline-small.pr--1,
html .mat-typography .mat-headline-large.ml--1 {
  padding-right: calc(var(--unit) * 1);
}

.padding-bottom-1,
html .mat-typography p.pb--1,
html .mat-typography .mat-headline-small.pb--1,
html .mat-typography .mat-headline-large.ml--1 {
  padding-bottom: calc(var(--unit) * 1);
}

.gap--1 {
  gap: calc(var(--unit) * 1);
}

.margin-left-2,
.ml--2,
html .mat-typography p.ml--2,
html .mat-typography .mat-headline-small.ml--2,
html .mat-typography .mat-headline-large.ml--2 {
  margin-left: calc(var(--unit) * 2);
}

.margin-top-2,
.mt--2,
html .mat-typography p.mt--2,
html .mat-typography .mat-headline-small.mt--2,
html .mat-typography .mat-headline-large.ml--2 {
  margin-top: calc(var(--unit) * 2);
}

.margin-right-2,
.mr--2,
html .mat-typography p.mr--2,
html .mat-typography .mat-headline-small.mr--2,
html .mat-typography .mat-headline-large.ml--2 {
  margin-right: calc(var(--unit) * 2);
}

.margin-bottom-2,
.mb--2,
html .mat-typography p.mb--2,
html .mat-typography .mat-headline-small.mb--2,
html .mat-typography .mat-headline-large.ml--2 {
  margin-bottom: calc(var(--unit) * 2);
}

.padding-left-2,
.pl--2,
html .mat-typography p.pl--2,
html .mat-typography .mat-headline-small.pl--2,
html .mat-typography .mat-headline-large.ml--2 {
  padding-left: calc(var(--unit) * 2);
}

.padding-top-2,
html .mat-typography p.pt--2,
html .mat-typography .mat-headline-small.pt--2,
html .mat-typography .mat-headline-large.ml--2 {
  padding-top: calc(var(--unit) * 2);
}

.padding-right-2,
html .mat-typography p.pr--2,
html .mat-typography .mat-headline-small.pr--2,
html .mat-typography .mat-headline-large.ml--2 {
  padding-right: calc(var(--unit) * 2);
}

.padding-bottom-2,
html .mat-typography p.pb--2,
html .mat-typography .mat-headline-small.pb--2,
html .mat-typography .mat-headline-large.ml--2 {
  padding-bottom: calc(var(--unit) * 2);
}

.gap--2 {
  gap: calc(var(--unit) * 2);
}

.margin-left-3,
.ml--3,
html .mat-typography p.ml--3,
html .mat-typography .mat-headline-small.ml--3,
html .mat-typography .mat-headline-large.ml--3 {
  margin-left: calc(var(--unit) * 3);
}

.margin-top-3,
.mt--3,
html .mat-typography p.mt--3,
html .mat-typography .mat-headline-small.mt--3,
html .mat-typography .mat-headline-large.ml--3 {
  margin-top: calc(var(--unit) * 3);
}

.margin-right-3,
.mr--3,
html .mat-typography p.mr--3,
html .mat-typography .mat-headline-small.mr--3,
html .mat-typography .mat-headline-large.ml--3 {
  margin-right: calc(var(--unit) * 3);
}

.margin-bottom-3,
.mb--3,
html .mat-typography p.mb--3,
html .mat-typography .mat-headline-small.mb--3,
html .mat-typography .mat-headline-large.ml--3 {
  margin-bottom: calc(var(--unit) * 3);
}

.padding-left-3,
.pl--3,
html .mat-typography p.pl--3,
html .mat-typography .mat-headline-small.pl--3,
html .mat-typography .mat-headline-large.ml--3 {
  padding-left: calc(var(--unit) * 3);
}

.padding-top-3,
html .mat-typography p.pt--3,
html .mat-typography .mat-headline-small.pt--3,
html .mat-typography .mat-headline-large.ml--3 {
  padding-top: calc(var(--unit) * 3);
}

.padding-right-3,
html .mat-typography p.pr--3,
html .mat-typography .mat-headline-small.pr--3,
html .mat-typography .mat-headline-large.ml--3 {
  padding-right: calc(var(--unit) * 3);
}

.padding-bottom-3,
html .mat-typography p.pb--3,
html .mat-typography .mat-headline-small.pb--3,
html .mat-typography .mat-headline-large.ml--3 {
  padding-bottom: calc(var(--unit) * 3);
}

.gap--3 {
  gap: calc(var(--unit) * 3);
}

.margin-left-4,
.ml--4,
html .mat-typography p.ml--4,
html .mat-typography .mat-headline-small.ml--4,
html .mat-typography .mat-headline-large.ml--4 {
  margin-left: calc(var(--unit) * 4);
}

.margin-top-4,
.mt--4,
html .mat-typography p.mt--4,
html .mat-typography .mat-headline-small.mt--4,
html .mat-typography .mat-headline-large.ml--4 {
  margin-top: calc(var(--unit) * 4);
}

.margin-right-4,
.mr--4,
html .mat-typography p.mr--4,
html .mat-typography .mat-headline-small.mr--4,
html .mat-typography .mat-headline-large.ml--4 {
  margin-right: calc(var(--unit) * 4);
}

.margin-bottom-4,
.mb--4,
html .mat-typography p.mb--4,
html .mat-typography .mat-headline-small.mb--4,
html .mat-typography .mat-headline-large.ml--4 {
  margin-bottom: calc(var(--unit) * 4);
}

.padding-left-4,
.pl--4,
html .mat-typography p.pl--4,
html .mat-typography .mat-headline-small.pl--4,
html .mat-typography .mat-headline-large.ml--4 {
  padding-left: calc(var(--unit) * 4);
}

.padding-top-4,
html .mat-typography p.pt--4,
html .mat-typography .mat-headline-small.pt--4,
html .mat-typography .mat-headline-large.ml--4 {
  padding-top: calc(var(--unit) * 4);
}

.padding-right-4,
html .mat-typography p.pr--4,
html .mat-typography .mat-headline-small.pr--4,
html .mat-typography .mat-headline-large.ml--4 {
  padding-right: calc(var(--unit) * 4);
}

.padding-bottom-4,
html .mat-typography p.pb--4,
html .mat-typography .mat-headline-small.pb--4,
html .mat-typography .mat-headline-large.ml--4 {
  padding-bottom: calc(var(--unit) * 4);
}

.gap--4 {
  gap: calc(var(--unit) * 4);
}

.margin-left-5,
.ml--5,
html .mat-typography p.ml--5,
html .mat-typography .mat-headline-small.ml--5,
html .mat-typography .mat-headline-large.ml--5 {
  margin-left: calc(var(--unit) * 5);
}

.margin-top-5,
.mt--5,
html .mat-typography p.mt--5,
html .mat-typography .mat-headline-small.mt--5,
html .mat-typography .mat-headline-large.ml--5 {
  margin-top: calc(var(--unit) * 5);
}

.margin-right-5,
.mr--5,
html .mat-typography p.mr--5,
html .mat-typography .mat-headline-small.mr--5,
html .mat-typography .mat-headline-large.ml--5 {
  margin-right: calc(var(--unit) * 5);
}

.margin-bottom-5,
.mb--5,
html .mat-typography p.mb--5,
html .mat-typography .mat-headline-small.mb--5,
html .mat-typography .mat-headline-large.ml--5 {
  margin-bottom: calc(var(--unit) * 5);
}

.padding-left-5,
.pl--5,
html .mat-typography p.pl--5,
html .mat-typography .mat-headline-small.pl--5,
html .mat-typography .mat-headline-large.ml--5 {
  padding-left: calc(var(--unit) * 5);
}

.padding-top-5,
html .mat-typography p.pt--5,
html .mat-typography .mat-headline-small.pt--5,
html .mat-typography .mat-headline-large.ml--5 {
  padding-top: calc(var(--unit) * 5);
}

.padding-right-5,
html .mat-typography p.pr--5,
html .mat-typography .mat-headline-small.pr--5,
html .mat-typography .mat-headline-large.ml--5 {
  padding-right: calc(var(--unit) * 5);
}

.padding-bottom-5,
html .mat-typography p.pb--5,
html .mat-typography .mat-headline-small.pb--5,
html .mat-typography .mat-headline-large.ml--5 {
  padding-bottom: calc(var(--unit) * 5);
}

.gap--5 {
  gap: calc(var(--unit) * 5);
}

.full-width {
  width: 100%;
}

.d--block {
  display: block;
}

.processing {
  position: relative;
}
.processing[disabled] {
  background: var(--surface-background, grey);
}
.processing--hide-content span:not(.dot) {
  opacity: 0;
}
.processing__root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.processing__root .dot {
  animation-name: processing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  height: 6px;
  width: 6px;
  display: block;
  background: var(--primary-color, orange);
  border-radius: 50%;
  border: 1px solid #fff;
}
.processing__root .dot:nth-child(2) {
  animation-delay: 0.2s;
}
.processing__root .dot:nth-child(3) {
  animation-delay: 0.4s;
}
.processing__root--center-center {
  align-items: center;
  justify-content: center;
}
.processing__root--bottom-center {
  align-items: end;
  justify-content: center;
  bottom: 4px;
  top: initial;
}

@keyframes processing {
  from {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
/**
 * This code represents a selection of SCSS code.
 * It is used for [describe purpose or functionality].
 *
 * [Add any additional details or explanations here]
 */
.dark .mat-menu-panel {
  background: #000;
}

:root {
  --color-blue-gray-blue-gray-100: #6f8087ff;
  --color-blue-gray-200: #455a64ff;
  --color-blue-gray-300: #37474fff;
  --color-blue-gray-400: #283236ff;
  --color-dark-mode-blue-completed: #59b5ebff;
  --color-dark-mode-surface-100: #161819ff;
  --color-dark-mode-surface-300: #1c2022ff;
  --color-dark-mode-surface-400: #22292cff;
  --color-dark-mode-surface-500: #283236ff;
  --color-dark-mode-green-successful: #52be80ff;
  --color-dark-mode-red-error: #ff6666ff;
  --color-dark-mode-stroke-surface-100: #6f80877f;
  --color-dark-mode-stroke-surface-300: #6f80877f;
  --color-dark-mode-stroke-surface-400: #6f808799;
  --color-dark-mode-yellow-warning: #ffd67bff;
  --color-neutral-gray-100: #f5f5f5ff;
  --color-neutral-gray-200: #ddddddff;
  --color-neutral-gray-300: #c4c4c4ff;
  --color-neutral-gray-400: #9a9a9aff;
  --color-neutral-gray-500: #4f4f4fff;
  --color-neutral-black: #121212ff;
  --color-neutral-white: #ffffffff;
  --color-orange-100: #fef5edff;
  --color-orange-200: #ffd7bcff;
  --color-orange-300: #ffb88aff;
  --color-orange-400: #ff9b58;
  --color-orange-500: #ff7d26ff;
  --color-orange-600: #f04e1bff;
  --color-orange-700: #f04f1b;
  --color-status-blue-completed: #30a3e6ff;
  --color-status-blue-completed-10: #30a3e619;
  --color-status-gray-expired-10: #9a9a9a19;
  --color-status-green-successful: #27ae60ff;
  --color-status-green-successful-10: #27ae6019;
  --color-status-red-error: #ff3838ff;
  --color-status-red-error-10: #ff383819;
  --color-status-yellow-warning: #ffcc5aff;
  --color-status-yellow-warning-10: #ffcc5a19;
  --color-vip-gold-100: #eddfc2ff;
  --color-vip-gold-200: #b59149ff;
  --color-vip-gold-300: #d2b478ff;
  --color-vip-gold-400: #b48e44ff;
  --surface-bluer-effect: #ffffffe5;
  --surface-echeck-blue: #006f9dff;
  --surface-echeck-green: #2ea53fff;
  --surface-visa-blue: #0052ccff;
  --color-box-shadow: rgba(18, 18, 18, 0.12);
  --status-completed: var(--color-status-blue-completed);
  --status-error: var(--color-status-red-error);
  --status-expired: var(--color-neutral-gray-400);
  --status-successful: var(--color-status-green-successful);
  --status-warning: var(--color-status-yellow-warning);
  --icon-limit: var(--color-neutral-gray-300);
  --icon-invert: var(--color-neutral-white);
  --text-highlighted: var(--color-orange-500);
  --text-highlighted-invert: var(--color-orange-400);
  --text-breadcrumbs-active: var(--color-neutral-black);
  --text-inactive: var(--color-neutral-gray-400);
  --icon-primary: var(--color-neutral-black);
  --icon-secondary: var(--color-neutral-gray-500);
  --icon-tertiary: var(--color-neutral-gray-400);
  --text-invert: var(--color-neutral-white);
  --text-limit: var(--color-neutral-gray-300);
  --text-primary: var(--color-neutral-black);
  --thumb-primary: var(--color-neutral-gray-300);
  --text-secondary: var(--color-neutral-gray-500);
  --text-tertiary: var(--color-neutral-gray-400);
  --border-field-active: var(--color-orange-500);
  --border-field-hover: var(--color-neutral-gray-500);
  --border-field-default: var(--color-neutral-gray-300);
  --border-field-inactive: var(--color-neutral-gray-200);
  --border-field-error: var(--color-status-red-error);
  --border-elevation-avatar: var(--color-neutral-white);
  --border-elevation-modals: var(--color-neutral-gray-200);
  --border-elevation-1: var(--color-neutral-gray-200);
  --border-elevation-4: var(--color-orange-500);
  --border-elevation-6: var(--color-orange-400);
  --surface-background: var(--color-neutral-white);
  --surface-active: var(--color-orange-500);
  --surface-inactive: var(--color-neutral-gray-200);
  --surface-graphic-1: var(--color-orange-200);
  --surface-graphic-2: var(--color-orange-300);
  --surface-footer: var(--color-neutral-gray-100);
  --surface-status-error: var(--color-status-red-error-10);
  --surface-status-warning: var(--color-status-yellow-warning-10);
  --surface-status-successful: var(--color-status-green-successful-10);
  --surface-status-completed: var(--color-status-blue-completed-10);
  --surface-status-expired: var(--color-status-gray-expired-10);
  --surface-elevation-1: var(--color-neutral-white);
  --surface-elevation-2: var(--color-neutral-gray-100);
  --surface-elevation-3: var(--color-orange-100);
  --surface-elevation-4: var(--color-orange-100);
  --surface-elevation-5: var(--color-orange-500);
  --surface-elevation-6: var(--color-dark-mode-surface-500);
  --surface-elevation-7: var(--color-neutral-gray-100);
  --surface-elevation-modal: var(--color-neutral-white);
  --gradients-primary-orange-gradient: linear-gradient(
    180deg,
    var(--color-orange-500) 0%,
    var(--color-orange-700) 100%
  );
  --gradients-primary-orange-gradient-buttons: linear-gradient(
    135deg,
    var(--color-orange-500) 0%,
    var(--color-orange-600) 100%
  );
  --gradients-primary-gold-gradient: linear-gradient(
    165deg,
    var(--color-orange-500) -5.61%,
    #ffe266 40.74%,
    var(--color-orange-500) 97.23%
  );
  --gradients-secundary-orange-gradient: linear-gradient(
    180deg,
    rgba(243, 144, 21, 0.7) 0%,
    rgba(240, 79, 27, 0.7) 100%
  );
  --gradients-gradient-for-supports-and-tabs: linear-gradient(
    180deg,
    rgba(255, 125, 38, 0) 0%,
    rgba(255, 125, 38, 0.1) 100%
  );
  --gradients-gradient-for-cards: linear-gradient(
    135deg,
    #ff7d26 0%,
    #ffd7bc 100%
  );
  --mdc-theme-error: var(--status-error);
}

[color-scheme=dark] {
  --surface-elevation-modal: var(--color-dark-mode-surface-300);
  --surface-elevation-1: var(--color-dark-mode-surface-100);
  --surface-elevation-2: var(--color-dark-mode-surface-400);
  --surface-elevation-3: var(--color-dark-mode-surface-500);
  --surface-elevation-4: var(--color-dark-mode-surface-400);
  --surface-elevation-5: var(--color-dark-mode-surface-500);
  --surface-elevation-6: var(--color-neutral-white);
  --surface-elevation-7: var(--color-dark-mode-surface-400);
  --surface-visa-blue: #5699fd;
  --surface-echeck-green: #46bd57;
  --surface-echeck-blue: #229ccf;
  --surface-bluer-effect: #161819;
  --surface-background: var(--color-neutral-black);
  --surface-active: var(--color-orange-400);
  --surface-inactive: var(--color-dark-mode-surface-400);
  --surface-graphic-1: var(--color-blue-gray-300);
  --surface-graphic-2: var(--color-blue-gray-200);
  --surface-footer: var(--surface-elevation-modal);
  --status-completed: var(--color-dark-mode-blue-completed);
  --status-error: var(--color-dark-mode-red-error);
  --status-expired: var(--color-neutral-gray-300);
  --status-successful: var(--color-dark-mode-green-successful);
  --status-warning: var(--color-dark-mode-yellow-warning);
  --thumb-primary: var(--color-neutral-gray-500);
  --text-limit: var(--color-neutral-gray-400);
  --text-invert: var(--color-neutral-gray-500);
  --text-highlighted: var(--color-orange-400);
  --text-highlighted-invert: var(--color-orange-500);
  --text-breadcrumbs-active: var(--color-orange-400);
  --icon-primary: var(--color-neutral-white);
  --icon-secondary: var(--color-neutral-gray-200);
  --icon-tertiary: var(--color-neutral-gray-300);
  --icon-limit: var(--color-neutral-gray-400);
  --icon-invert: var(--color-neutral-gray-400);
  --border-elevation-avatar: var(--color-dark-mode-surface-400);
  --border-elevation-1: var(--color-dark-mode-stroke-surface-100);
  --border-elevation-4: var(--color-dark-mode-stroke-surface-400);
  --border-field-default: var(--color-neutral-gray-400);
  --border-field-inactive: var(--color-neutral-gray-500);
  --border-field-active: var(--color-orange-400);
  --border-elevation-modals: var(--color-dark-mode-stroke-surface-300);
  --gradients-gradient-for-supports-and-tabs: linear-gradient(
    180deg,
    rgba(34, 41, 44, 0) 0%,
    #22292c 100%
  );
  --gradients-gradient-for-cards: linear-gradient(
    135deg,
    #37474f 100%,
    #37474f 0%
  );
}

:root {
  --primary-color: var(--sys-primary);
  --text-highlighted: var(--sys-primary);
  --border-field-default: var(--color-neutral-gray-400);
  --border-field-active: var(--color-orange-400);
  --border-elevation-1: #6f80877f;
  --primary-border-color: #e0e0e0;
  --text-primary: var(--mat-app-text-color);
  --breadcrumb-height: 36px;
  --warn-color: var(--color-status-red-error);
}

body .grecaptcha-badge {
  visibility: hidden;
}

body.fz-auth .grecaptcha-badge {
  visibility: visible;
}

table div.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  animation: placeHolderShimmer 1.2s linear infinite;
}
table div.loader::before {
  content: "";
  display: block;
  background: var(--color-neutral-gray-400);
  border-radius: 12px;
  width: 96%;
  height: 8px;
}
table div.loader::after {
  content: "";
  display: block;
  background: var(--color-neutral-gray-400);
  border-radius: 12px;
  width: 85%;
  height: 8px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}
.animated-number-change {
  transition: --num 2s;
  counter-set: num var(--num);
}
.animated-number-change::after {
  content: counter(num);
}

@media (max-width: 768px) {
  .hide-in-mobile {
    display: none;
  }
}

fieldset {
  border-radius: 12px;
}
fieldset legend {
  padding-inline: 8px;
}
fieldset legend:has(mat-icon) {
  padding-inline-end: 32px;
}
fieldset legend label mat-icon {
  position: absolute;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --unit: 8px;
  color-scheme: dark;
  /**
   * Apply by default the dark theme for the whole app and the background color
   */
  background-color: var(--mat-app-background-color);
  color: var(--mat-app-text-color);
  --mat-app-background-color: var(--sys-background);
  --mat-app-text-color: var(--sys-on-background);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: color-mix(in srgb, var(--sys-on-surface) 10%, transparent);
  --mat-option-selected-state-label-text-color: var(--sys-on-secondary-container);
  --mat-option-label-text-color: var(--sys-on-surface);
  --mat-option-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-option-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-option-selected-state-layer-color: var(--sys-secondary-container);
  --mat-option-label-text-font: var(--sys-label-large-font);
  --mat-option-label-text-line-height: var(--sys-label-large-line-height);
  --mat-option-label-text-size: var(--sys-body-large-size);
  --mat-option-label-text-tracking: var(--sys-label-large-tracking);
  --mat-option-label-text-weight: var(--sys-body-large-weight);
  --mat-optgroup-label-text-color: var(--sys-on-surface-variant);
  --mat-optgroup-label-text-font: var(--sys-title-small-font);
  --mat-optgroup-label-text-line-height: var(--sys-title-small-line-height);
  --mat-optgroup-label-text-size: var(--sys-title-small-size);
  --mat-optgroup-label-text-tracking: var(--sys-title-small-tracking);
  --mat-optgroup-label-text-weight: var(--sys-title-small-weight);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-elevated-card-container-color: var(--sys-surface);
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-color: var(--sys-surface);
  --mdc-outlined-card-outline-color: var(--sys-outline-variant);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
  --mat-card-subtitle-text-color: var(--sys-on-surface);
  --mat-card-title-text-font: var(--sys-title-large-font);
  --mat-card-title-text-line-height: var(--sys-title-large-line-height);
  --mat-card-title-text-size: var(--sys-title-large-size);
  --mat-card-title-text-tracking: var(--sys-title-large-tracking);
  --mat-card-title-text-weight: var(--sys-title-large-weight);
  --mat-card-subtitle-text-font: var(--sys-title-medium-font);
  --mat-card-subtitle-text-line-height: var(--sys-title-medium-line-height);
  --mat-card-subtitle-text-size: var(--sys-title-medium-size);
  --mat-card-subtitle-text-tracking: var(--sys-title-medium-tracking);
  --mat-card-subtitle-text-weight: var(--sys-title-medium-weight);
  --mdc-linear-progress-active-indicator-color: var(--sys-primary);
  --mdc-linear-progress-track-color: var(--sys-surface-variant);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-color: var(--sys-inverse-surface);
  --mdc-plain-tooltip-supporting-text-color: var(--sys-inverse-on-surface);
  --mdc-plain-tooltip-supporting-text-line-height: var(--sys-body-small-line-height);
  --mdc-plain-tooltip-supporting-text-font: var(--sys-body-small-font);
  --mdc-plain-tooltip-supporting-text-size: var(--sys-body-small-size);
  --mdc-plain-tooltip-supporting-text-weight: var(--sys-body-small-weight);
  --mdc-plain-tooltip-supporting-text-tracking: var(--sys-body-small-tracking);
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-filled-text-field-caret-color: var(--sys-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-filled-text-field-container-color: var(--sys-surface-variant);
  --mdc-filled-text-field-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 4%, transparent);
  --mdc-filled-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-hover-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-color: var(--sys-on-surface);
  --mdc-filled-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-filled-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-filled-text-field-error-label-text-color: var(--sys-error);
  --mdc-filled-text-field-active-indicator-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-active-indicator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-hover-active-indicator-color: var(--sys-on-surface);
  --mdc-filled-text-field-error-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--sys-on-error-container);
  --mdc-filled-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-filled-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-filled-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-filled-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-caret-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-outlined-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-hover-label-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-outline-color: var(--sys-outline);
  --mdc-outlined-text-field-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-text-field-hover-outline-color: var(--sys-on-surface);
  --mdc-outlined-text-field-error-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-error-outline-color: var(--sys-error);
  --mdc-outlined-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-outlined-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-outlined-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-outlined-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mat-form-field-focus-select-arrow-color: var(--sys-primary);
  --mat-form-field-disabled-input-text-placeholder-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-state-layer-color: var(--sys-on-surface);
  --mat-form-field-error-text-color: var(--sys-error);
  --mat-form-field-select-option-text-color: #000000;
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-leading-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-trailing-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-trailing-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-error-focus-trailing-icon-color: var(--sys-error);
  --mat-form-field-error-hover-trailing-icon-color: var(--sys-on-error-container);
  --mat-form-field-error-trailing-icon-color: var(--sys-error);
  --mat-form-field-enabled-select-arrow-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-select-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: var(--sys-body-large-font);
  --mat-form-field-container-text-line-height: var(--sys-body-large-line-height);
  --mat-form-field-container-text-size: var(--sys-body-large-size);
  --mat-form-field-container-text-tracking: var(--sys-body-large-tracking);
  --mat-form-field-container-text-weight: var(--sys-body-large-weight);
  --mat-form-field-subscript-text-font: var(--sys-body-small-font);
  --mat-form-field-subscript-text-line-height: var(--sys-body-small-line-height);
  --mat-form-field-subscript-text-size: var(--sys-body-small-size);
  --mat-form-field-subscript-text-tracking: var(--sys-body-small-tracking);
  --mat-form-field-subscript-text-weight: var(--sys-body-small-weight);
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-select-panel-background-color: var(--sys-surface-container);
  --mat-select-enabled-trigger-text-color: var(--sys-on-surface);
  --mat-select-disabled-trigger-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-placeholder-text-color: var(--sys-on-surface-variant);
  --mat-select-enabled-arrow-color: var(--sys-on-surface-variant);
  --mat-select-disabled-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-focused-arrow-color: var(--sys-primary);
  --mat-select-invalid-arrow-color: var(--sys-error);
  --mat-select-trigger-text-font: var(--sys-body-large-font);
  --mat-select-trigger-text-line-height: var(--sys-body-large-line-height);
  --mat-select-trigger-text-size: var(--sys-body-large-size);
  --mat-select-trigger-text-tracking: var(--sys-body-large-tracking);
  --mat-select-trigger-text-weight: var(--sys-body-large-weight);
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: var(--sys-surface-container);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-color: var(--sys-surface);
  --mdc-dialog-subhead-color: var(--sys-on-surface);
  --mdc-dialog-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-dialog-subhead-font: var(--sys-headline-small-font);
  --mdc-dialog-subhead-line-height: var(--sys-headline-small-line-height);
  --mdc-dialog-subhead-size: var(--sys-headline-small-size);
  --mdc-dialog-subhead-weight: var(--sys-headline-small-weight);
  --mdc-dialog-subhead-tracking: var(--sys-headline-small-tracking);
  --mdc-dialog-supporting-text-font: var(--sys-body-medium-font);
  --mdc-dialog-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-dialog-supporting-text-size: var(--sys-body-medium-size);
  --mdc-dialog-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-dialog-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-dialog-container-shape: 28px;
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
  --mdc-chip-outline-color: var(--sys-outline);
  --mdc-chip-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-outline-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-chip-elevated-selected-container-color: var(--sys-secondary-container);
  --mdc-chip-flat-disabled-selected-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-label-text-color: var(--sys-on-secondary-container);
  --mdc-chip-with-icon-icon-color: var(--sys-on-surface-variant);
  --mdc-chip-with-icon-disabled-icon-color: var(--sys-on-surface);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-secondary-container);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--sys-on-surface-variant);
  --mdc-chip-label-text-font: var(--sys-label-large-font);
  --mdc-chip-label-text-line-height: var(--sys-label-large-line-height);
  --mdc-chip-label-text-size: var(--sys-label-large-size);
  --mdc-chip-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-chip-label-text-weight: var(--sys-label-large-weight);
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
  --mat-chip-trailing-action-state-layer-color: var(--sys-on-surface-variant);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-secondary-container);
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: var(--sys-on-surface);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-secondary-container);
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-handle-color: var(--sys-on-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-focus-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-primary);
  --mdc-switch-selected-hover-track-color: var(--sys-primary);
  --mdc-switch-selected-pressed-track-color: var(--sys-primary);
  --mdc-switch-selected-track-color: var(--sys-primary);
  --mdc-switch-disabled-selected-handle-color: var(--sys-surface);
  --mdc-switch-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-switch-disabled-selected-track-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-handle-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-disabled-unselected-track-color: var(--sys-surface-variant);
  --mdc-switch-selected-icon-color: var(--sys-on-primary-container);
  --mdc-switch-unselected-focus-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-focus-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-handle-color: var(--sys-outline);
  --mdc-switch-unselected-hover-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-hover-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-unselected-pressed-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-pressed-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-track-color: var(--sys-surface-variant);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
  --mat-switch-label-text-font: var(--sys-body-medium-font);
  --mat-switch-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-switch-label-text-size: var(--sys-body-medium-size);
  --mat-switch-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-switch-label-text-weight: var(--sys-body-medium-weight);
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
  --mdc-radio-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-radio-disabled-unselected-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-radio-unselected-pressed-icon-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-primary);
  --mdc-radio-selected-hover-icon-color: var(--sys-primary);
  --mdc-radio-selected-icon-color: var(--sys-primary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-primary);
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mat-radio-ripple-color: var(--sys-on-surface);
  --mat-radio-checked-ripple-color: var(--sys-primary);
  --mat-radio-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-radio-label-text-color: var(--sys-on-surface);
  --mat-radio-label-text-font: var(--sys-body-medium-font);
  --mat-radio-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-radio-label-text-size: var(--sys-body-medium-size);
  --mat-radio-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-radio-label-text-weight: var(--sys-body-medium-weight);
  --mat-radio-touch-target-display: block;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: var(--sys-primary);
  --mdc-slider-focus-handle-color: var(--sys-primary);
  --mdc-slider-hover-handle-color: var(--sys-primary);
  --mdc-slider-active-track-color: var(--sys-primary);
  --mdc-slider-inactive-track-color: var(--sys-surface-variant);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--sys-on-surface-variant);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-primary);
  --mdc-slider-disabled-active-track-color: var(--sys-on-surface);
  --mdc-slider-disabled-handle-color: var(--sys-on-surface);
  --mdc-slider-disabled-inactive-track-color: var(--sys-on-surface);
  --mdc-slider-label-container-color: var(--sys-primary);
  --mdc-slider-label-label-text-color: var(--sys-on-primary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-primary);
  --mdc-slider-with-tick-marks-disabled-container-color: var(--sys-on-surface);
  --mdc-slider-label-label-text-font: var(--sys-label-medium-font);
  --mdc-slider-label-label-text-size: var(--sys-label-medium-size);
  --mdc-slider-label-label-text-line-height: var(--sys-label-medium-line-height);
  --mdc-slider-label-label-text-tracking: var(--sys-label-medium-tracking);
  --mdc-slider-label-label-text-weight: var(--sys-label-medium-weight);
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: var(--sys-primary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-primary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-primary) 20%, transparent);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
  --mat-menu-item-label-text-color: var(--sys-on-surface);
  --mat-menu-item-icon-color: var(--sys-on-surface-variant);
  --mat-menu-item-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-menu-item-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-menu-container-color: var(--sys-surface-container);
  --mat-menu-divider-color: var(--sys-surface-variant);
  --mat-menu-item-label-text-font: var(--sys-label-large-font);
  --mat-menu-item-label-text-size: var(--sys-label-large-size);
  --mat-menu-item-label-text-tracking: var(--sys-label-large-tracking);
  --mat-menu-item-label-text-line-height: var(--sys-label-large-line-height);
  --mat-menu-item-label-text-weight: var(--sys-label-large-weight);
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-base-elevation-level: 2;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: var(--sys-primary-container);
  --mdc-list-list-item-disabled-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-leading-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-selected-trailing-icon-color: var(--sys-primary);
  --mdc-list-list-item-disabled-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-leading-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: var(--sys-body-large-font);
  --mdc-list-list-item-label-text-line-height: var(--sys-body-large-line-height);
  --mdc-list-list-item-label-text-size: var(--sys-body-large-size);
  --mdc-list-list-item-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-list-list-item-label-text-weight: var(--sys-body-large-weight);
  --mdc-list-list-item-supporting-text-font: var(--sys-body-medium-font);
  --mdc-list-list-item-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-list-list-item-supporting-text-size: var(--sys-body-medium-size);
  --mdc-list-list-item-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-list-list-item-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-list-list-item-trailing-supporting-text-font: var(--sys-label-small-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: var(--sys-label-small-line-height);
  --mdc-list-list-item-trailing-supporting-text-size: var(--sys-label-small-size);
  --mdc-list-list-item-trailing-supporting-text-tracking: var(--sys-label-small-tracking);
  --mdc-list-list-item-trailing-supporting-text-weight: var(--sys-label-small-weight);
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: var(--sys-secondary-container);
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
  --mat-paginator-container-text-color: var(--sys-on-surface);
  --mat-paginator-container-background-color: var(--sys-surface);
  --mat-paginator-enabled-icon-color: var(--sys-on-surface-variant);
  --mat-paginator-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-paginator-container-text-font: var(--sys-body-small-font);
  --mat-paginator-container-text-line-height: var(--sys-body-small-line-height);
  --mat-paginator-container-text-size: var(--sys-body-small-size);
  --mat-paginator-container-text-tracking: var(--sys-body-small-tracking);
  --mat-paginator-container-text-weight: var(--sys-body-small-weight);
  --mat-paginator-select-trigger-text-size: var(--sys-body-small-size);
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-color: var(--sys-primary);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: var(--sys-surface-variant);
  --mat-tab-header-pagination-icon-color: var(--sys-on-surface);
  --mat-tab-header-inactive-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-inactive-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-indicator-color: var(--sys-primary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-primary);
  --mat-tab-header-label-text-font: var(--sys-title-small-font);
  --mat-tab-header-label-text-size: var(--sys-title-small-size);
  --mat-tab-header-label-text-tracking: var(--sys-title-small-tracking);
  --mat-tab-header-label-text-line-height: var(--sys-title-small-line-height);
  --mat-tab-header-label-text-weight: var(--sys-title-small-weight);
  --mat-tab-header-divider-height: 1px;
  --mdc-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-primary);
  --mdc-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-icon-color: var(--sys-primary);
  --mdc-checkbox-unselected-focus-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-primary);
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mat-checkbox-label-text-font: var(--sys-body-medium-font);
  --mat-checkbox-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-checkbox-label-text-size: var(--sys-body-medium-size);
  --mat-checkbox-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-checkbox-label-text-weight: var(--sys-body-medium-weight);
  --mat-checkbox-touch-target-display: block;
  --mdc-text-button-label-text-color: var(--sys-primary);
  --mdc-text-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-text-button-label-text-font: var(--sys-label-large-font);
  --mdc-text-button-label-text-size: var(--sys-label-large-size);
  --mdc-text-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-text-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: var(--sys-surface);
  --mdc-protected-button-label-text-color: var(--sys-primary);
  --mdc-protected-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-protected-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-protected-button-label-text-font: var(--sys-label-large-font);
  --mdc-protected-button-label-text-size: var(--sys-label-large-size);
  --mdc-protected-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-protected-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
  --mdc-filled-button-container-color: var(--sys-primary);
  --mdc-filled-button-label-text-color: var(--sys-on-primary);
  --mdc-filled-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-filled-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-button-label-text-font: var(--sys-label-large-font);
  --mdc-filled-button-label-text-size: var(--sys-label-large-size);
  --mdc-filled-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-filled-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-outlined-button-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-button-label-text-color: var(--sys-primary);
  --mdc-outlined-button-outline-color: var(--sys-outline);
  --mdc-outlined-button-label-text-font: var(--sys-label-large-font);
  --mdc-outlined-button-label-text-size: var(--sys-label-large-size);
  --mdc-outlined-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-outlined-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
  --mat-text-button-state-layer-color: var(--sys-primary);
  --mat-text-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
  --mat-protected-button-state-layer-color: var(--sys-primary);
  --mat-protected-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
  --mat-filled-button-state-layer-color: var(--sys-on-primary);
  --mat-filled-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-primary) 12%, transparent);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
  --mat-outlined-button-state-layer-color: var(--sys-primary);
  --mat-outlined-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
  --mdc-icon-button-icon-color: var(--sys-on-surface-variant);
  --mdc-icon-button-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
  --mat-icon-button-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-on-surface-variant) 12%, transparent);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: var(--sys-label-large-font);
  --mdc-extended-fab-label-text-size: var(--sys-label-large-size);
  --mdc-extended-fab-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-extended-fab-label-text-weight: var(--sys-label-large-weight);
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: var(--sys-primary-container);
  --mdc-fab-container-shape: 16px;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: var(--sys-primary-container);
  --mdc-fab-small-container-shape: 12px;
  --mat-fab-foreground-color: var(--sys-on-primary-container);
  --mat-fab-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-fab-touch-target-display: block;
  --mat-fab-small-foreground-color: var(--sys-on-primary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-small-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-snackbar-container-color: var(--sys-inverse-surface);
  --mdc-snackbar-supporting-text-color: var(--sys-inverse-on-surface);
  --mdc-snackbar-supporting-text-font: var(--sys-body-medium-font);
  --mdc-snackbar-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-snackbar-supporting-text-size: var(--sys-body-medium-size);
  --mdc-snackbar-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-snackbar-container-shape: 4px;
  --mat-snack-bar-button-color: var(--sys-inverse-primary);
  --mat-table-background-color: var(--sys-surface);
  --mat-table-header-headline-color: var(--sys-on-surface);
  --mat-table-row-item-label-text-color: var(--sys-on-surface);
  --mat-table-row-item-outline-color: var(--sys-outline);
  --mat-table-header-headline-font: var(--sys-title-small-font);
  --mat-table-header-headline-line-height: var(--sys-title-small-line-height);
  --mat-table-header-headline-size: var(--sys-title-small-size);
  --mat-table-header-headline-weight: var(--sys-title-small-weight);
  --mat-table-header-headline-tracking: var(--sys-title-small-tracking);
  --mat-table-row-item-label-text-font: var(--sys-body-medium-font);
  --mat-table-row-item-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-row-item-label-text-size: var(--sys-body-medium-size);
  --mat-table-row-item-label-text-weight: var(--sys-body-medium-weight);
  --mat-table-row-item-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-footer-supporting-text-font: var(--sys-body-medium-font);
  --mat-table-footer-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-footer-supporting-text-size: var(--sys-body-medium-size);
  --mat-table-footer-supporting-text-weight: var(--sys-body-medium-weight);
  --mat-table-footer-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
  --mdc-circular-progress-active-indicator-color: var(--sys-primary);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mat-badge-background-color: var(--sys-error);
  --mat-badge-text-color: var(--sys-on-error);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-error) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-error);
  --mat-badge-text-font: var(--sys-label-small-font);
  --mat-badge-text-size: var(--sys-label-small-size);
  --mat-badge-text-weight: var(--sys-label-small-weight);
  --mat-badge-large-size-text-size: var(--sys-label-small-size);
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: 16px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 16px;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-small-size-text-size: 0;
  --mat-bottom-sheet-container-text-color: var(--sys-on-surface);
  --mat-bottom-sheet-container-background-color: var(--sys-surface-container-low);
  --mat-bottom-sheet-container-text-font: var(--sys-body-large-font);
  --mat-bottom-sheet-container-text-line-height: var(--sys-body-large-line-height);
  --mat-bottom-sheet-container-text-size: var(--sys-body-large-size);
  --mat-bottom-sheet-container-text-tracking: var(--sys-body-large-tracking);
  --mat-bottom-sheet-container-text-weight: var(--sys-body-large-weight);
  --mat-bottom-sheet-container-shape: 28px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: var(--sys-on-surface);
  --mat-standard-button-toggle-state-layer-color: var(--sys-on-surface);
  --mat-standard-button-toggle-selected-state-background-color: var(--sys-secondary-container);
  --mat-standard-button-toggle-selected-state-text-color: var(--sys-on-secondary-container);
  --mat-standard-button-toggle-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-standard-button-toggle-divider-color: var(--sys-outline);
  --mat-standard-button-toggle-label-text-font: var(--sys-label-large-font);
  --mat-standard-button-toggle-label-text-line-height: var(--sys-label-large-line-height);
  --mat-standard-button-toggle-label-text-size: var(--sys-label-large-size);
  --mat-standard-button-toggle-label-text-tracking: var(--sys-label-large-tracking);
  --mat-standard-button-toggle-label-text-weight: var(--sys-label-large-weight);
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-primary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-datepicker-toggle-active-state-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--sys-secondary-container);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--sys-secondary);
  --mat-datepicker-toggle-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-body-label-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-period-button-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-period-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-header-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-date-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-primary);
  --mat-datepicker-range-input-separator-color: var(--sys-on-surface);
  --mat-datepicker-range-input-disabled-state-separator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-range-input-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-container-background-color: var(--sys-surface-container-high);
  --mat-datepicker-calendar-container-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-text-font: var(--sys-body-medium-font);
  --mat-datepicker-calendar-text-size: var(--sys-body-medium-size);
  --mat-datepicker-calendar-body-label-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-body-label-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-period-button-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-period-button-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-header-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-header-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-divider-color: var(--sys-outline);
  --mat-divider-width: 1px;
  --mat-expansion-container-background-color: var(--sys-surface);
  --mat-expansion-container-text-color: var(--sys-on-surface);
  --mat-expansion-actions-divider-color: var(--sys-outline);
  --mat-expansion-header-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-expansion-header-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-expansion-header-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-expansion-header-text-color: var(--sys-on-surface);
  --mat-expansion-header-description-color: var(--sys-on-surface-variant);
  --mat-expansion-header-indicator-color: var(--sys-on-surface-variant);
  --mat-expansion-header-text-font: var(--sys-title-medium-font);
  --mat-expansion-header-text-size: var(--sys-title-medium-size);
  --mat-expansion-header-text-weight: var(--sys-title-medium-weight);
  --mat-expansion-header-text-line-height: var(--sys-title-medium-line-height);
  --mat-expansion-header-text-tracking: var(--sys-title-medium-tracking);
  --mat-expansion-container-text-font: var(--sys-body-large-font);
  --mat-expansion-container-text-line-height: var(--sys-body-large-line-height);
  --mat-expansion-container-text-size: var(--sys-body-large-size);
  --mat-expansion-container-text-tracking: var(--sys-body-large-tracking);
  --mat-expansion-container-text-weight: var(--sys-body-large-weight);
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
  --mat-grid-list-tile-header-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-header-secondary-text-size: var(--sys-body-medium);
  --mat-grid-list-tile-footer-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-footer-secondary-text-size: var(--sys-body-medium);
  --mat-icon-color: inherit;
  --mat-sidenav-container-background-color: var(--sys-surface);
  --mat-sidenav-container-text-color: var(--sys-on-surface-variant);
  --mat-sidenav-content-background-color: var(--sys-background);
  --mat-sidenav-content-text-color: var(--sys-on-background);
  --mat-sidenav-scrim-color: rgba(79, 79, 79, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
  --mat-stepper-header-icon-foreground-color: var(--sys-surface);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-container-color: var(--sys-surface);
  --mat-stepper-line-color: var(--sys-outline);
  --mat-stepper-header-hover-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 8%, transparent);
  --mat-stepper-header-focus-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 12%, transparent);
  --mat-stepper-header-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-optional-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-selected-state-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-label-text-color: var(--sys-error);
  --mat-stepper-header-icon-background-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-icon-foreground-color: var(--sys-error);
  --mat-stepper-container-text-font: var(--sys-body-medium-font);
  --mat-stepper-header-label-text-font: var(--sys-title-small-font);
  --mat-stepper-header-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-error-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: var(--sys-on-surface);
  --mat-toolbar-container-background-color: var(--sys-surface);
  --mat-toolbar-container-text-color: var(--sys-on-surface);
  --mat-toolbar-title-text-font: var(--sys-title-large-font);
  --mat-toolbar-title-text-line-height: var(--sys-title-large-line-height);
  --mat-toolbar-title-text-size: var(--sys-title-large-size);
  --mat-toolbar-title-text-tracking: var(--sys-title-large-tracking);
  --mat-toolbar-title-text-weight: var(--sys-title-large-weight);
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
  --mat-tree-container-background-color: var(--sys-surface);
  --mat-tree-node-text-color: var(--sys-on-surface);
  --mat-tree-node-text-font: var(--sys-body-large-font);
  --mat-tree-node-text-size: var(--sys-body-large-size);
  --mat-tree-node-text-weight: var(--sys-body-large-weight);
  --mat-tree-node-min-height: 48px;
  --sys-background: #000000;
  --sys-error: #ffb2b2;
  --sys-error-container: #800000;
  --sys-inverse-on-surface: #4f4f4f;
  --sys-inverse-primary: #ff7d26;
  --sys-inverse-surface: #dddddd;
  --sys-on-background: #dddddd;
  --sys-on-error: #800000;
  --sys-on-error-container: #ffb2b2;
  --sys-on-primary: #ff7d26;
  --sys-on-primary-container: #ffd7bc;
  --sys-on-primary-fixed: #000000;
  --sys-on-primary-fixed-variant: #ff7d26;
  --sys-on-secondary: #22292c;
  --sys-on-secondary-container: #455a64;
  --sys-on-secondary-fixed: #000000;
  --sys-on-secondary-fixed-variant: #22292c;
  --sys-on-surface: #dddddd;
  --sys-on-surface-variant: #dddddd;
  --sys-on-tertiary: #ff7d26;
  --sys-on-tertiary-container: #c4c4c4;
  --sys-on-tertiary-fixed: #000000;
  --sys-on-tertiary-fixed-variant: #ff7d26;
  --sys-outline: #263238;
  --sys-outline-variant: #4f4f4f;
  --sys-primary: #ff7d26;
  --sys-primary-container: #ff7d26;
  --sys-primary-fixed: #ffd7bc;
  --sys-primary-fixed-dim: #ff7d26;
  --sys-scrim: #000000;
  --sys-secondary: #455a64;
  --sys-secondary-container: #22292c;
  --sys-secondary-fixed: #455a64;
  --sys-secondary-fixed-dim: #455a64;
  --sys-shadow: #000000;
  --sys-surface: #000000;
  --sys-surface-bright: #4f4f4f;
  --sys-surface-container: #000000;
  --sys-surface-container-high: #000000;
  --sys-surface-container-highest: #4f4f4f;
  --sys-surface-container-low: #000000;
  --sys-surface-container-lowest: #000000;
  --sys-surface-dim: #000000;
  --sys-surface-tint: #ff7d26;
  --sys-surface-variant: #4f4f4f;
  --sys-tertiary: #c4c4c4;
  --sys-tertiary-container: #ff7d26;
  --sys-tertiary-fixed: #c4c4c4;
  --sys-tertiary-fixed-dim: #c4c4c4;
  --sys-body-large: 400 1rem / 1.5rem Roboto, sans-serif;
  --sys-body-large-font: Roboto, sans-serif;
  --sys-body-large-line-height: 1.5rem;
  --sys-body-large-size: 1rem;
  --sys-body-large-tracking: 0.031rem;
  --sys-body-large-weight: 400;
  --sys-body-medium: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-body-medium-font: Roboto, sans-serif;
  --sys-body-medium-line-height: 1.25rem;
  --sys-body-medium-size: 0.875rem;
  --sys-body-medium-tracking: 0.016rem;
  --sys-body-medium-weight: 400;
  --sys-body-small: 400 0.75rem / 1rem Roboto, sans-serif;
  --sys-body-small-font: Roboto, sans-serif;
  --sys-body-small-line-height: 1rem;
  --sys-body-small-size: 0.75rem;
  --sys-body-small-tracking: 0.025rem;
  --sys-body-small-weight: 400;
  --sys-display-large: 400 3.562rem / 4rem Roboto;
  --sys-display-large-font: Roboto;
  --sys-display-large-line-height: 4rem;
  --sys-display-large-size: 3.562rem;
  --sys-display-large-tracking: -0.016rem;
  --sys-display-large-weight: 400;
  --sys-display-medium: 400 2.812rem / 3.25rem Roboto;
  --sys-display-medium-font: Roboto;
  --sys-display-medium-line-height: 3.25rem;
  --sys-display-medium-size: 2.812rem;
  --sys-display-medium-tracking: 0;
  --sys-display-medium-weight: 400;
  --sys-display-small: 400 2.25rem / 2.75rem Roboto;
  --sys-display-small-font: Roboto;
  --sys-display-small-line-height: 2.75rem;
  --sys-display-small-size: 2.25rem;
  --sys-display-small-tracking: 0;
  --sys-display-small-weight: 400;
  --sys-headline-large: 400 2rem / 2.5rem Roboto;
  --sys-headline-large-font: Roboto;
  --sys-headline-large-line-height: 2.5rem;
  --sys-headline-large-size: 2rem;
  --sys-headline-large-tracking: 0;
  --sys-headline-large-weight: 400;
  --sys-headline-medium: 400 1.75rem / 2.25rem Roboto;
  --sys-headline-medium-font: Roboto;
  --sys-headline-medium-line-height: 2.25rem;
  --sys-headline-medium-size: 1.75rem;
  --sys-headline-medium-tracking: 0;
  --sys-headline-medium-weight: 400;
  --sys-headline-small: 400 1.5rem / 2rem Roboto;
  --sys-headline-small-font: Roboto;
  --sys-headline-small-line-height: 2rem;
  --sys-headline-small-size: 1.5rem;
  --sys-headline-small-tracking: 0;
  --sys-headline-small-weight: 400;
  --sys-label-large: 500 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-label-large-font: Roboto, sans-serif;
  --sys-label-large-line-height: 1.25rem;
  --sys-label-large-size: 0.875rem;
  --sys-label-large-tracking: 0.006rem;
  --sys-label-large-weight: 500;
  --sys-label-large-weight-prominent: 400;
  --sys-label-medium: 500 0.75rem / 1rem Roboto, sans-serif;
  --sys-label-medium-font: Roboto, sans-serif;
  --sys-label-medium-line-height: 1rem;
  --sys-label-medium-size: 0.75rem;
  --sys-label-medium-tracking: 0.031rem;
  --sys-label-medium-weight: 500;
  --sys-label-medium-weight-prominent: 400;
  --sys-label-small: 500 0.688rem / 1rem Roboto, sans-serif;
  --sys-label-small-font: Roboto, sans-serif;
  --sys-label-small-line-height: 1rem;
  --sys-label-small-size: 0.688rem;
  --sys-label-small-tracking: 0.031rem;
  --sys-label-small-weight: 500;
  --sys-title-large: 400 1.375rem / 1.75rem Roboto;
  --sys-title-large-font: Roboto;
  --sys-title-large-line-height: 1.75rem;
  --sys-title-large-size: 1.375rem;
  --sys-title-large-tracking: 0;
  --sys-title-large-weight: 400;
  --sys-title-medium: 500 1rem / 1.5rem Roboto, sans-serif;
  --sys-title-medium-font: Roboto, sans-serif;
  --sys-title-medium-line-height: 1.5rem;
  --sys-title-medium-size: 1rem;
  --sys-title-medium-tracking: 0.009rem;
  --sys-title-medium-weight: 500;
  --sys-title-small: 500 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-title-small-font: Roboto, sans-serif;
  --sys-title-small-line-height: 1.25rem;
  --sys-title-small-size: 0.875rem;
  --sys-title-small-tracking: 0.006rem;
  --sys-title-small-weight: 500;
  /**
   * Override material theme tokens directly
   */
  --sys-background: #121212;
  --sys-surface: #121212;
  --sys-primary: #ff7d26;
  --sys-on-primary: #ffffff;
  --sys-surface-variant: #22292c;
  --sys-on-surface-variant: #6f8087;
  --mdc-filled-button-container-shape: var(--fz-container-shape);
  --mdc-outlined-button-container-shape: var(--fz-container-shape);
  --mdc-protected-button-container-shape: var(--fz-container-shape);
  --mdc-text-button-container-shape: var(--fz-container-shape);
  --mat-standard-button-toggle-shape: var(--fz-container-shape);
  /**
   * Apply the light theme only when the user prefers light themes.
   */
}
.mat-theme-loaded-marker {
  display: none;
}

html .mat-primary {
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
}
html .mat-primary.mat-icon {
  --mat-icon-color: var(--sys-primary);
}
html .mat-primary.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html .mat-primary.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html .mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: var(--sys-primary);
  --mat-icon-button-state-layer-color: var(--sys-primary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
}
html .mat-primary .mdc-list-item__start,
html .mat-primary .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html .mat-badge {
  --mat-badge-background-color: var(--sys-primary);
  --mat-badge-text-color: var(--sys-on-primary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-primary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-primary);
}
html .mat-accent {
  --mat-option-selected-state-label-text-color: var(--sys-on-tertiary-container);
  --mat-option-selected-state-layer-color: var(--sys-tertiary-container);
  --mdc-circular-progress-active-indicator-color: var(--sys-tertiary);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-tertiary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-tertiary);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-tertiary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-tertiary);
}
html .mat-accent.mat-icon {
  --mat-icon-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html .mat-accent.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-tertiary);
  --mdc-slider-focus-handle-color: var(--sys-tertiary);
  --mdc-slider-hover-handle-color: var(--sys-tertiary);
  --mdc-slider-active-track-color: var(--sys-tertiary);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-tertiary);
  --mdc-slider-label-container-color: var(--sys-tertiary);
  --mdc-slider-label-label-text-color: var(--sys-on-tertiary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-tertiary);
  --mat-slider-ripple-color: var(--sys-tertiary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 20%, transparent);
}
html .mat-accent.mat-mdc-tab-group, html .mat-accent.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-tertiary);
  --mat-tab-header-active-focus-indicator-color: var(--sys-tertiary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-handle-color: var(--sys-on-tertiary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-focus-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-tertiary);
  --mdc-switch-selected-hover-track-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-track-color: var(--sys-tertiary);
  --mdc-switch-selected-track-color: var(--sys-tertiary);
  --mdc-switch-selected-icon-color: var(--sys-on-tertiary-container);
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html .mat-accent.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-tertiary);
  --mdc-linear-progress-track-color: var(--sys-tertiary-container);
}
html .mat-accent.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-tertiary);
  --mdc-outlined-text-field-caret-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-tertiary);
  --mat-form-field-focus-select-arrow-color: var(--sys-tertiary);
}
html .mat-accent.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-tertiary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-tertiary);
  --mdc-protected-button-label-text-color: var(--sys-tertiary);
  --mdc-filled-button-container-color: var(--sys-tertiary);
  --mdc-filled-button-label-text-color: var(--sys-on-tertiary);
  --mdc-outlined-button-label-text-color: var(--sys-tertiary);
  --mat-text-button-state-layer-color: var(--sys-tertiary);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mat-protected-button-state-layer-color: var(--sys-tertiary);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mat-filled-button-state-layer-color: var(--sys-on-tertiary);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-tertiary) 12%, transparent);
  --mat-outlined-button-state-layer-color: var(--sys-tertiary);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mdc-icon-button-icon-color: var(--sys-tertiary);
  --mat-icon-button-state-layer-color: var(--sys-tertiary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
html .mat-accent.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-tertiary-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-tertiary-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-tertiary-container);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-tertiary-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-tertiary-container);
}
html .mat-accent .mdc-list-item__start,
html .mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
html .mat-accent.mat-mdc-fab, html .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-container-color: var(--sys-tertiary-container);
  --mdc-fab-small-container-color: var(--sys-tertiary-container);
  --mat-fab-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
  --mat-fab-small-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
}
html .mat-badge-accent {
  --mat-badge-background-color: var(--sys-tertiary);
  --mat-badge-text-color: var(--sys-on-tertiary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-tertiary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-tertiary);
}
html .mat-warn {
  --mat-option-selected-state-label-text-color: var(--sys-on-error-container);
  --mat-option-selected-state-layer-color: var(--sys-error-container);
  --mdc-circular-progress-active-indicator-color: var(--sys-error);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-error);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-error);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-error);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-error);
}
html .mat-warn.mat-icon {
  --mat-icon-color: var(--sys-error);
}
html .mat-warn.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html .mat-warn.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-error);
  --mdc-slider-focus-handle-color: var(--sys-error);
  --mdc-slider-hover-handle-color: var(--sys-error);
  --mdc-slider-active-track-color: var(--sys-error);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-error);
  --mdc-slider-label-container-color: var(--sys-error);
  --mdc-slider-label-label-text-color: var(--sys-on-error);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-error);
  --mat-slider-ripple-color: var(--sys-error);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-error) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-error) 20%, transparent);
}
html .mat-warn.mat-mdc-tab-group, html .mat-warn.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-error);
  --mat-tab-header-active-focus-indicator-color: var(--sys-error);
  --mat-tab-header-active-hover-indicator-color: var(--sys-error);
}
html .mat-warn.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-error);
  --mdc-switch-selected-handle-color: var(--sys-on-error);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-error);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-error);
  --mdc-switch-selected-focus-handle-color: var(--sys-error-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-error-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-error-container);
  --mdc-switch-selected-focus-track-color: var(--sys-error);
  --mdc-switch-selected-hover-track-color: var(--sys-error);
  --mdc-switch-selected-pressed-track-color: var(--sys-error);
  --mdc-switch-selected-track-color: var(--sys-error);
  --mdc-switch-selected-icon-color: var(--sys-on-error-container);
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html .mat-warn.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-error);
}
html .mat-warn.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
  --mat-radio-checked-ripple-color: var(--sys-error);
}
html .mat-warn.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-error);
  --mdc-linear-progress-track-color: var(--sys-error-container);
}
html .mat-warn.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-error);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-caret-color: var(--sys-error);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-error);
  --mat-form-field-focus-select-arrow-color: var(--sys-error);
}
html .mat-warn.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-error);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-error);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-error-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-error);
}
html .mat-warn.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-error);
  --mdc-protected-button-label-text-color: var(--sys-error);
  --mdc-filled-button-container-color: var(--sys-error);
  --mdc-filled-button-label-text-color: var(--sys-on-error);
  --mdc-outlined-button-label-text-color: var(--sys-error);
  --mat-text-button-state-layer-color: var(--sys-error);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mat-protected-button-state-layer-color: var(--sys-error);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mat-filled-button-state-layer-color: var(--sys-on-error);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-error) 12%, transparent);
  --mat-outlined-button-state-layer-color: var(--sys-error);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mdc-icon-button-icon-color: var(--sys-error);
  --mat-icon-button-state-layer-color: var(--sys-error);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
html .mat-warn.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-error-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-error-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-error-container);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-error-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-error-container);
}
html .mat-warn .mdc-list-item__start,
html .mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
  --mat-radio-checked-ripple-color: var(--sys-error);
}
html .mat-display-large, html .mat-typography .mat-display-large, html .mat-typography h1 {
  font: var(--sys-display-large);
  letter-spacing: var(--sys-display-large-tracking);
  margin: 0 0 0.5em;
}
html .mat-display-medium, html .mat-typography .mat-display-medium, html .mat-typography h2 {
  font: var(--sys-display-medium);
  letter-spacing: var(--sys-display-medium-tracking);
  margin: 0 0 0.5em;
}
html .mat-display-small, html .mat-typography .mat-display-small, html .mat-typography h3 {
  font: var(--sys-display-small);
  letter-spacing: var(--sys-display-small-tracking);
  margin: 0 0 0.5em;
}
html .mat-headline-large, html .mat-typography .mat-headline-large, html .mat-typography h4 {
  font: var(--sys-headline-large);
  letter-spacing: var(--sys-headline-large-tracking);
  margin: 0 0 0.5em;
}
html .mat-headline-medium, html .mat-typography .mat-headline-medium, html .mat-typography h5 {
  font: var(--sys-headline-medium);
  letter-spacing: var(--sys-headline-medium-tracking);
  margin: 0 0 0.5em;
}
html .mat-headline-small, html .mat-typography .mat-headline-small, html .mat-typography h6 {
  font: var(--sys-headline-small);
  letter-spacing: var(--sys-headline-small-tracking);
  margin: 0 0 0.5em;
}
html .mat-title-large, html .mat-typography .mat-title-large {
  font: var(--sys-title-large);
  letter-spacing: var(--sys-title-large-tracking);
}
html .mat-title-medium, html .mat-typography .mat-title-medium {
  font: var(--sys-title-medium);
  letter-spacing: var(--sys-title-medium-tracking);
}
html .mat-title-small, html .mat-typography .mat-title-small {
  font: var(--sys-title-small);
  letter-spacing: var(--sys-title-small-tracking);
}
html .mat-body-large, html .mat-typography .mat-body-large, html .mat-typography {
  font: var(--sys-body-large);
  letter-spacing: var(--sys-body-large-tracking);
}
html .mat-body-large p, html .mat-typography .mat-body-large p, html .mat-typography p {
  margin: 0 0 0.75em;
}
html .mat-body-medium, html .mat-typography .mat-body-medium {
  font: var(--sys-body-medium);
  letter-spacing: var(--sys-body-medium-tracking);
}
html .mat-body-small, html .mat-typography .mat-body-small {
  font: var(--sys-body-small);
  letter-spacing: var(--sys-body-small-tracking);
}
html .mat-label-large, html .mat-typography .mat-label-large {
  font: var(--sys-label-large);
  letter-spacing: var(--sys-label-large-tracking);
}
html .mat-label-medium, html .mat-typography .mat-label-medium {
  font: var(--sys-label-medium);
  letter-spacing: var(--sys-label-medium-tracking);
}
html .mat-label-small, html .mat-typography .mat-label-small {
  font: var(--sys-label-small);
  letter-spacing: var(--sys-label-small-tracking);
}
html .fz-button--small {
  --mdc-outlined-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-raised-button-container-height: 36px;
  --mdc-text-button-container-height: 36px;
}
html .fz-button--medium {
  --mdc-outlined-button-container-height: 48px;
  --mdc-filled-button-container-height: 48px;
  --mdc-raised-button-container-height: 48px;
  --mdc-text-button-container-height: 48px;
}
html .fz-button--large {
  --mdc-outlined-button-container-height: 56px;
  --mdc-filled-button-container-height: 56px;
  --mdc-raised-button-container-height: 56px;
  --mdc-text-button-container-height: 56px;
}
html[color-scheme=light] {
  color-scheme: light;
  --mat-app-background-color: var(--sys-background);
  --mat-app-text-color: var(--sys-on-background);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: color-mix(in srgb, var(--sys-on-surface) 10%, transparent);
  --mat-option-selected-state-label-text-color: var(--sys-on-secondary-container);
  --mat-option-label-text-color: var(--sys-on-surface);
  --mat-option-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-option-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-option-selected-state-layer-color: var(--sys-secondary-container);
  --mat-optgroup-label-text-color: var(--sys-on-surface-variant);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-elevated-card-container-color: var(--sys-surface);
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: var(--sys-surface);
  --mdc-outlined-card-outline-color: var(--sys-outline-variant);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: var(--sys-on-surface);
  --mdc-linear-progress-active-indicator-color: var(--sys-primary);
  --mdc-linear-progress-track-color: var(--sys-surface-variant);
  --mdc-plain-tooltip-container-color: var(--sys-inverse-surface);
  --mdc-plain-tooltip-supporting-text-color: var(--sys-inverse-on-surface);
  --mdc-filled-text-field-caret-color: var(--sys-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-filled-text-field-container-color: var(--sys-surface-variant);
  --mdc-filled-text-field-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 4%, transparent);
  --mdc-filled-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-hover-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-color: var(--sys-on-surface);
  --mdc-filled-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-filled-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-filled-text-field-error-label-text-color: var(--sys-error);
  --mdc-filled-text-field-active-indicator-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-active-indicator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-hover-active-indicator-color: var(--sys-on-surface);
  --mdc-filled-text-field-error-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-caret-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-outlined-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-hover-label-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-outline-color: var(--sys-outline);
  --mdc-outlined-text-field-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-text-field-hover-outline-color: var(--sys-on-surface);
  --mdc-outlined-text-field-error-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-error-outline-color: var(--sys-error);
  --mat-form-field-focus-select-arrow-color: var(--sys-primary);
  --mat-form-field-disabled-input-text-placeholder-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-state-layer-color: var(--sys-on-surface);
  --mat-form-field-error-text-color: var(--sys-error);
  --mat-form-field-select-option-text-color: #000000;
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-leading-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-trailing-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-trailing-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-error-focus-trailing-icon-color: var(--sys-error);
  --mat-form-field-error-hover-trailing-icon-color: var(--sys-on-error-container);
  --mat-form-field-error-trailing-icon-color: var(--sys-error);
  --mat-form-field-enabled-select-arrow-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-select-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-select-panel-background-color: var(--sys-surface-container);
  --mat-select-enabled-trigger-text-color: var(--sys-on-surface);
  --mat-select-disabled-trigger-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-placeholder-text-color: var(--sys-on-surface-variant);
  --mat-select-enabled-arrow-color: var(--sys-on-surface-variant);
  --mat-select-disabled-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-focused-arrow-color: var(--sys-primary);
  --mat-select-invalid-arrow-color: var(--sys-error);
  --mat-autocomplete-background-color: var(--sys-surface-container);
  --mdc-dialog-container-color: var(--sys-surface);
  --mdc-dialog-subhead-color: var(--sys-on-surface);
  --mdc-dialog-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-chip-outline-color: var(--sys-outline);
  --mdc-chip-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-outline-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-chip-elevated-selected-container-color: var(--sys-secondary-container);
  --mdc-chip-flat-disabled-selected-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-label-text-color: var(--sys-on-secondary-container);
  --mdc-chip-with-icon-icon-color: var(--sys-on-surface-variant);
  --mdc-chip-with-icon-disabled-icon-color: var(--sys-on-surface);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-secondary-container);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--sys-on-surface-variant);
  --mat-chip-trailing-action-state-layer-color: var(--sys-on-surface-variant);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-secondary-container);
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: var(--sys-on-surface);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-secondary-container);
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-handle-color: var(--sys-on-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-focus-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-primary);
  --mdc-switch-selected-hover-track-color: var(--sys-primary);
  --mdc-switch-selected-pressed-track-color: var(--sys-primary);
  --mdc-switch-selected-track-color: var(--sys-primary);
  --mdc-switch-disabled-selected-handle-color: var(--sys-surface);
  --mdc-switch-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-switch-disabled-selected-track-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-handle-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-disabled-unselected-track-color: var(--sys-surface-variant);
  --mdc-switch-selected-icon-color: var(--sys-on-primary-container);
  --mdc-switch-unselected-focus-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-focus-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-handle-color: var(--sys-outline);
  --mdc-switch-unselected-hover-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-hover-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-unselected-pressed-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-pressed-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-track-color: var(--sys-surface-variant);
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
  --mdc-radio-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-radio-disabled-unselected-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-radio-unselected-pressed-icon-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-primary);
  --mdc-radio-selected-hover-icon-color: var(--sys-primary);
  --mdc-radio-selected-icon-color: var(--sys-primary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-primary);
  --mat-radio-ripple-color: var(--sys-on-surface);
  --mat-radio-checked-ripple-color: var(--sys-primary);
  --mat-radio-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-radio-label-text-color: var(--sys-on-surface);
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: var(--sys-primary);
  --mdc-slider-focus-handle-color: var(--sys-primary);
  --mdc-slider-hover-handle-color: var(--sys-primary);
  --mdc-slider-active-track-color: var(--sys-primary);
  --mdc-slider-inactive-track-color: var(--sys-surface-variant);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--sys-on-surface-variant);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-primary);
  --mdc-slider-disabled-active-track-color: var(--sys-on-surface);
  --mdc-slider-disabled-handle-color: var(--sys-on-surface);
  --mdc-slider-disabled-inactive-track-color: var(--sys-on-surface);
  --mdc-slider-label-container-color: var(--sys-primary);
  --mdc-slider-label-label-text-color: var(--sys-on-primary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-primary);
  --mdc-slider-with-tick-marks-disabled-container-color: var(--sys-on-surface);
  --mat-slider-ripple-color: var(--sys-primary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-primary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-primary) 20%, transparent);
  --mat-menu-item-label-text-color: var(--sys-on-surface);
  --mat-menu-item-icon-color: var(--sys-on-surface-variant);
  --mat-menu-item-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-menu-item-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-menu-container-color: var(--sys-surface-container);
  --mat-menu-divider-color: var(--sys-surface-variant);
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: var(--sys-primary-container);
  --mdc-list-list-item-disabled-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-leading-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-selected-trailing-icon-color: var(--sys-primary);
  --mdc-list-list-item-disabled-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-leading-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-list-active-indicator-color: var(--sys-secondary-container);
  --mat-paginator-container-text-color: var(--sys-on-surface);
  --mat-paginator-container-background-color: var(--sys-surface);
  --mat-paginator-enabled-icon-color: var(--sys-on-surface-variant);
  --mat-paginator-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-tab-indicator-active-indicator-color: var(--sys-primary);
  --mat-tab-header-divider-color: var(--sys-surface-variant);
  --mat-tab-header-pagination-icon-color: var(--sys-on-surface);
  --mat-tab-header-inactive-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-inactive-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-indicator-color: var(--sys-primary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-primary);
  --mdc-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-primary);
  --mdc-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-icon-color: var(--sys-primary);
  --mdc-checkbox-unselected-focus-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-primary);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mdc-text-button-label-text-color: var(--sys-primary);
  --mdc-text-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: var(--sys-surface);
  --mdc-protected-button-label-text-color: var(--sys-primary);
  --mdc-protected-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-protected-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-button-container-color: var(--sys-primary);
  --mdc-filled-button-label-text-color: var(--sys-on-primary);
  --mdc-filled-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-filled-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-button-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-button-label-text-color: var(--sys-primary);
  --mdc-outlined-button-outline-color: var(--sys-outline);
  --mat-text-button-state-layer-color: var(--sys-primary);
  --mat-text-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-state-layer-color: var(--sys-primary);
  --mat-protected-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-state-layer-color: var(--sys-on-primary);
  --mat-filled-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-primary) 12%, transparent);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-state-layer-color: var(--sys-primary);
  --mat-outlined-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-color: var(--sys-on-surface-variant);
  --mdc-icon-button-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-icon-button-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-on-surface-variant) 12%, transparent);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: var(--sys-primary-container);
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: var(--sys-primary-container);
  --mat-fab-foreground-color: var(--sys-on-primary-container);
  --mat-fab-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-fab-small-foreground-color: var(--sys-on-primary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-small-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-snackbar-container-color: var(--sys-inverse-surface);
  --mdc-snackbar-supporting-text-color: var(--sys-inverse-on-surface);
  --mat-snack-bar-button-color: var(--sys-inverse-primary);
  --mat-table-background-color: var(--sys-surface);
  --mat-table-header-headline-color: var(--sys-on-surface);
  --mat-table-row-item-label-text-color: var(--sys-on-surface);
  --mat-table-row-item-outline-color: var(--sys-outline);
  --mdc-circular-progress-active-indicator-color: var(--sys-primary);
  --mat-badge-background-color: var(--sys-error);
  --mat-badge-text-color: var(--sys-on-error);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-error) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-error);
  --mat-bottom-sheet-container-text-color: var(--sys-on-surface);
  --mat-bottom-sheet-container-background-color: var(--sys-surface-container-low);
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: var(--sys-on-surface);
  --mat-standard-button-toggle-state-layer-color: var(--sys-on-surface);
  --mat-standard-button-toggle-selected-state-background-color: var(--sys-secondary-container);
  --mat-standard-button-toggle-selected-state-text-color: var(--sys-on-secondary-container);
  --mat-standard-button-toggle-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-standard-button-toggle-divider-color: var(--sys-outline);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-primary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-datepicker-toggle-active-state-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--sys-secondary-container);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--sys-secondary);
  --mat-datepicker-toggle-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-body-label-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-period-button-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-period-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-header-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-date-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-primary);
  --mat-datepicker-range-input-separator-color: var(--sys-on-surface);
  --mat-datepicker-range-input-disabled-state-separator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-range-input-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-container-background-color: var(--sys-surface-container-high);
  --mat-datepicker-calendar-container-text-color: var(--sys-on-surface);
  --mat-divider-color: var(--sys-outline);
  --mat-expansion-container-background-color: var(--sys-surface);
  --mat-expansion-container-text-color: var(--sys-on-surface);
  --mat-expansion-actions-divider-color: var(--sys-outline);
  --mat-expansion-header-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-expansion-header-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-expansion-header-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-expansion-header-text-color: var(--sys-on-surface);
  --mat-expansion-header-description-color: var(--sys-on-surface-variant);
  --mat-expansion-header-indicator-color: var(--sys-on-surface-variant);
  --mat-sidenav-container-background-color: var(--sys-surface);
  --mat-sidenav-container-text-color: var(--sys-on-surface-variant);
  --mat-sidenav-content-background-color: var(--sys-background);
  --mat-sidenav-content-text-color: var(--sys-on-background);
  --mat-sidenav-scrim-color: rgba(79, 79, 79, 0.4);
  --mat-stepper-header-icon-foreground-color: var(--sys-surface);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-container-color: var(--sys-surface);
  --mat-stepper-line-color: var(--sys-outline);
  --mat-stepper-header-hover-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 8%, transparent);
  --mat-stepper-header-focus-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 12%, transparent);
  --mat-stepper-header-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-optional-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-selected-state-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-label-text-color: var(--sys-error);
  --mat-stepper-header-icon-background-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-icon-foreground-color: var(--sys-error);
  --mat-sort-arrow-color: var(--sys-on-surface);
  --mat-toolbar-container-background-color: var(--sys-surface);
  --mat-toolbar-container-text-color: var(--sys-on-surface);
  --mat-tree-container-background-color: var(--sys-surface);
  --mat-tree-node-text-color: var(--sys-on-surface);
  --sys-background: #f5f5f5;
  --sys-error: #ff1a1a;
  --sys-error-container: #ffb2b2;
  --sys-inverse-on-surface: #f5f5f5;
  --sys-inverse-primary: #ff7d26;
  --sys-inverse-surface: #4f4f4f;
  --sys-on-background: #000000;
  --sys-on-error: #ffffff;
  --sys-on-error-container: #000000;
  --sys-on-primary: #ffffff;
  --sys-on-primary-container: #000000;
  --sys-on-primary-fixed: #000000;
  --sys-on-primary-fixed-variant: #ff7d26;
  --sys-on-secondary: #ffffff;
  --sys-on-secondary-container: #000000;
  --sys-on-secondary-fixed: #000000;
  --sys-on-secondary-fixed-variant: #22292c;
  --sys-on-surface: #000000;
  --sys-on-surface-variant: #4f4f4f;
  --sys-on-tertiary: #ffffff;
  --sys-on-tertiary-container: #000000;
  --sys-on-tertiary-fixed: #000000;
  --sys-on-tertiary-fixed-variant: #ff7d26;
  --sys-outline: #dddddd;
  --sys-outline-variant: #dddddd;
  --sys-primary: #ff7d26;
  --sys-primary-container: #ffd7bc;
  --sys-primary-fixed: #ffd7bc;
  --sys-primary-fixed-dim: #ff7d26;
  --sys-scrim: #000000;
  --sys-secondary: #22292c;
  --sys-secondary-container: #455a64;
  --sys-secondary-fixed: #455a64;
  --sys-secondary-fixed-dim: #455a64;
  --sys-shadow: #000000;
  --sys-surface: #f5f5f5;
  --sys-surface-bright: #f5f5f5;
  --sys-surface-container: #ffffff;
  --sys-surface-container-high: #f5f5f5;
  --sys-surface-container-highest: #dddddd;
  --sys-surface-container-low: #f5f5f5;
  --sys-surface-container-lowest: #ffffff;
  --sys-surface-dim: ;
  --sys-surface-tint: #ff7d26;
  --sys-surface-variant: #dddddd;
  --sys-tertiary: #9a9a9a;
  --sys-tertiary-container: #c4c4c4;
  --sys-tertiary-fixed: #c4c4c4;
  --sys-tertiary-fixed-dim: #c4c4c4;
  --sys-background: #fff;
  --sys-surface: #fff;
  --mdc-switch-unselected-handle-color: #889392;
}
html[color-scheme=light] .mat-primary {
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
}
html[color-scheme=light] .mat-primary.mat-icon {
  --mat-icon-color: var(--sys-primary);
}
html[color-scheme=light] .mat-primary.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-primary.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: var(--sys-primary);
  --mat-icon-button-state-layer-color: var(--sys-primary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
}
html[color-scheme=light] .mat-primary .mdc-list-item__start,
html[color-scheme=light] .mat-primary .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-badge {
  --mat-badge-background-color: var(--sys-primary);
  --mat-badge-text-color: var(--sys-on-primary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-primary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-primary);
}
html[color-scheme=light] .mat-accent {
  --mat-option-selected-state-label-text-color: var(--sys-on-tertiary-container);
  --mat-option-selected-state-layer-color: var(--sys-tertiary-container);
  --mdc-circular-progress-active-indicator-color: var(--sys-tertiary);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-tertiary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-tertiary);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-tertiary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-tertiary);
}
html[color-scheme=light] .mat-accent.mat-icon {
  --mat-icon-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-accent.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-tertiary);
  --mdc-slider-focus-handle-color: var(--sys-tertiary);
  --mdc-slider-hover-handle-color: var(--sys-tertiary);
  --mdc-slider-active-track-color: var(--sys-tertiary);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-tertiary);
  --mdc-slider-label-container-color: var(--sys-tertiary);
  --mdc-slider-label-label-text-color: var(--sys-on-tertiary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-tertiary);
  --mat-slider-ripple-color: var(--sys-tertiary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 20%, transparent);
}
html[color-scheme=light] .mat-accent.mat-mdc-tab-group, html[color-scheme=light] .mat-accent.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-tertiary);
  --mat-tab-header-active-focus-indicator-color: var(--sys-tertiary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-handle-color: var(--sys-on-tertiary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-focus-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-tertiary);
  --mdc-switch-selected-hover-track-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-track-color: var(--sys-tertiary);
  --mdc-switch-selected-track-color: var(--sys-tertiary);
  --mdc-switch-selected-icon-color: var(--sys-on-tertiary-container);
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-accent.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-tertiary);
  --mdc-linear-progress-track-color: var(--sys-tertiary-container);
}
html[color-scheme=light] .mat-accent.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-tertiary);
  --mdc-outlined-text-field-caret-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-tertiary);
  --mat-form-field-focus-select-arrow-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-tertiary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-tertiary);
  --mdc-protected-button-label-text-color: var(--sys-tertiary);
  --mdc-filled-button-container-color: var(--sys-tertiary);
  --mdc-filled-button-label-text-color: var(--sys-on-tertiary);
  --mdc-outlined-button-label-text-color: var(--sys-tertiary);
  --mat-text-button-state-layer-color: var(--sys-tertiary);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mat-protected-button-state-layer-color: var(--sys-tertiary);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mat-filled-button-state-layer-color: var(--sys-on-tertiary);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-tertiary) 12%, transparent);
  --mat-outlined-button-state-layer-color: var(--sys-tertiary);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
  --mdc-icon-button-icon-color: var(--sys-tertiary);
  --mat-icon-button-state-layer-color: var(--sys-tertiary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
html[color-scheme=light] .mat-accent.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-tertiary-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-tertiary-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-tertiary-container);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-tertiary-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-tertiary-container);
}
html[color-scheme=light] .mat-accent .mdc-list-item__start,
html[color-scheme=light] .mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
html[color-scheme=light] .mat-accent.mat-mdc-fab, html[color-scheme=light] .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-container-color: var(--sys-tertiary-container);
  --mdc-fab-small-container-color: var(--sys-tertiary-container);
  --mat-fab-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
  --mat-fab-small-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
}
html[color-scheme=light] .mat-badge-accent {
  --mat-badge-background-color: var(--sys-tertiary);
  --mat-badge-text-color: var(--sys-on-tertiary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-tertiary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-tertiary);
}
html[color-scheme=light] .mat-warn {
  --mat-option-selected-state-label-text-color: var(--sys-on-error-container);
  --mat-option-selected-state-layer-color: var(--sys-error-container);
  --mdc-circular-progress-active-indicator-color: var(--sys-error);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-error);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-error);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-error);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-error);
}
html[color-scheme=light] .mat-warn.mat-icon {
  --mat-icon-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-warn.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-error);
  --mdc-slider-focus-handle-color: var(--sys-error);
  --mdc-slider-hover-handle-color: var(--sys-error);
  --mdc-slider-active-track-color: var(--sys-error);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-error);
  --mdc-slider-label-container-color: var(--sys-error);
  --mdc-slider-label-label-text-color: var(--sys-on-error);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-error);
  --mat-slider-ripple-color: var(--sys-error);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-error) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-error) 20%, transparent);
}
html[color-scheme=light] .mat-warn.mat-mdc-tab-group, html[color-scheme=light] .mat-warn.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-error);
  --mat-tab-header-active-focus-indicator-color: var(--sys-error);
  --mat-tab-header-active-hover-indicator-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-error);
  --mdc-switch-selected-handle-color: var(--sys-on-error);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-error);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-error);
  --mdc-switch-selected-focus-handle-color: var(--sys-error-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-error-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-error-container);
  --mdc-switch-selected-focus-track-color: var(--sys-error);
  --mdc-switch-selected-hover-track-color: var(--sys-error);
  --mdc-switch-selected-pressed-track-color: var(--sys-error);
  --mdc-switch-selected-track-color: var(--sys-error);
  --mdc-switch-selected-icon-color: var(--sys-on-error-container);
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
html[color-scheme=light] .mat-warn.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
  --mat-radio-checked-ripple-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-error);
  --mdc-linear-progress-track-color: var(--sys-error-container);
}
html[color-scheme=light] .mat-warn.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-error);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-caret-color: var(--sys-error);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-error);
  --mat-form-field-focus-select-arrow-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-error);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-error);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-error-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-error);
}
html[color-scheme=light] .mat-warn.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-error);
  --mdc-protected-button-label-text-color: var(--sys-error);
  --mdc-filled-button-container-color: var(--sys-error);
  --mdc-filled-button-label-text-color: var(--sys-on-error);
  --mdc-outlined-button-label-text-color: var(--sys-error);
  --mat-text-button-state-layer-color: var(--sys-error);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mat-protected-button-state-layer-color: var(--sys-error);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mat-filled-button-state-layer-color: var(--sys-on-error);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-error) 12%, transparent);
  --mat-outlined-button-state-layer-color: var(--sys-error);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
  --mdc-icon-button-icon-color: var(--sys-error);
  --mat-icon-button-state-layer-color: var(--sys-error);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
html[color-scheme=light] .mat-warn.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-error-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-error-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-error-container);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-error-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-error-container);
}
html[color-scheme=light] .mat-warn .mdc-list-item__start,
html[color-scheme=light] .mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
  --mat-radio-checked-ripple-color: var(--sys-error);
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.material-icons {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.primary-color {
  color: var(--primary-color);
}

.help-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 2px;
}

html .mat-body-large p,
html .mat-typography .mat-body-large p,
html .mat-headline-small,
html .mat-typography .mat-headline-small,
html .mat-typography h6 {
  margin: 0;
  padding: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: light-dark(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) !important;
  color: inherit !important;
}
input:-webkit-autofill {
  border: none;
  border-radius: 0.3rem;
  caret-color: var(--sys-text);
  background: transparent;
  -webkit-text-fill-color: var(--sys-on-primary);
  -webkit-background-clip: text;
  box-shadow: 0 0 0 50px rgba(0, 0, 0, 0) inset;
}