@import "./colors.scss";

:root {
  //  Figma Tokens
  --color-blue-gray-blue-gray-100: #6f8087ff;
  --color-blue-gray-200: #455a64ff;
  --color-blue-gray-300: #37474fff;
  --color-blue-gray-400: #283236ff;
  --color-dark-mode-blue-completed: #59b5ebff;
  --color-dark-mode-surface-100: #161819ff;
  --color-dark-mode-surface-300: #1c2022ff;
  --color-dark-mode-surface-400: #22292cff;
  --color-dark-mode-surface-500: #283236ff;
  --color-dark-mode-green-successful: #52be80ff;
  --color-dark-mode-red-error: #ff6666ff;
  --color-dark-mode-stroke-surface-100: #6f80877f;
  --color-dark-mode-stroke-surface-300: #6f80877f;
  --color-dark-mode-stroke-surface-400: #6f808799;
  --color-dark-mode-yellow-warning: #ffd67bff;
  --color-neutral-gray-100: #f5f5f5ff;
  --color-neutral-gray-200: #ddddddff;
  --color-neutral-gray-300: #c4c4c4ff;
  --color-neutral-gray-400: #9a9a9aff;
  --color-neutral-gray-500: #4f4f4fff;
  --color-neutral-black: #121212ff;
  --color-neutral-white: #ffffffff;
  --color-orange-100: #fef5edff;
  --color-orange-200: #ffd7bcff;
  --color-orange-300: #ffb88aff;
  --color-orange-400: #ff9b58;
  --color-orange-500: #ff7d26ff;
  --color-orange-600: #f04e1bff;
  --color-orange-700: #f04f1b;
  --color-status-blue-completed: #30a3e6ff;
  --color-status-blue-completed-10: #30a3e619;
  --color-status-gray-expired-10: #9a9a9a19;
  --color-status-green-successful: #27ae60ff;
  --color-status-green-successful-10: #27ae6019;
  --color-status-red-error: #ff3838ff;
  --color-status-red-error-10: #ff383819;
  --color-status-yellow-warning: #ffcc5aff;
  --color-status-yellow-warning-10: #ffcc5a19;
  --color-vip-gold-100: #eddfc2ff;
  --color-vip-gold-200: #b59149ff;
  --color-vip-gold-300: #d2b478ff;
  --color-vip-gold-400: #b48e44ff;
  --surface-bluer-effect: #ffffffe5;
  --surface-echeck-blue: #006f9dff;
  --surface-echeck-green: #2ea53fff;
  --surface-visa-blue: #0052ccff;
  --color-box-shadow: rgba(18, 18, 18, 0.12);

  //  Text & Icons
  --status-completed: var(--color-status-blue-completed);
  --status-error: var(--color-status-red-error);
  --status-expired: var(--color-neutral-gray-400);
  --status-successful: var(--color-status-green-successful);
  --status-warning: var(--color-status-yellow-warning);
  --icon-limit: var(--color-neutral-gray-300);
  --icon-invert: var(--color-neutral-white);
  --text-highlighted: var(--color-orange-500);
  --text-highlighted-invert: var(--color-orange-400);
  --text-breadcrumbs-active: var(--color-neutral-black);
  --text-inactive: var(--color-neutral-gray-400);
  --icon-primary: var(--color-neutral-black);
  --icon-secondary: var(--color-neutral-gray-500);
  --icon-tertiary: var(--color-neutral-gray-400);
  --text-invert: var(--color-neutral-white);
  --text-limit: var(--color-neutral-gray-300);
  --text-primary: var(--color-neutral-black);
  --thumb-primary: var(--color-neutral-gray-300);
  --text-secondary: var(--color-neutral-gray-500);
  --text-tertiary: var(--color-neutral-gray-400);

  //  Borders
  --border-field-active: var(--color-orange-500);
  --border-field-hover: var(--color-neutral-gray-500);
  --border-field-default: var(--color-neutral-gray-300);
  --border-field-inactive: var(--color-neutral-gray-200);
  --border-field-error: var(--color-status-red-error);
  --border-elevation-avatar: var(--color-neutral-white);
  --border-elevation-modals: var(--color-neutral-gray-200);
  --border-elevation-1: var(--color-neutral-gray-200);
  --border-elevation-4: var(--color-orange-500);
  --border-elevation-6: var(--color-orange-400);

  //  Surfaces
  --surface-background: var(--color-neutral-white);
  --surface-active: var(--color-orange-500);
  --surface-inactive: var(--color-neutral-gray-200);
  --surface-graphic-1: var(--color-orange-200);
  --surface-graphic-2: var(--color-orange-300);
  --surface-footer: var(--color-neutral-gray-100);

  --surface-status-error: var(--color-status-red-error-10);
  --surface-status-warning: var(--color-status-yellow-warning-10);
  --surface-status-successful: var(--color-status-green-successful-10);
  --surface-status-completed: var(--color-status-blue-completed-10);
  --surface-status-expired: var(--color-status-gray-expired-10);

  --surface-elevation-1: var(--color-neutral-white);
  --surface-elevation-2: var(--color-neutral-gray-100);
  --surface-elevation-3: var(--color-orange-100);
  --surface-elevation-4: var(--color-orange-100);
  --surface-elevation-5: var(--color-orange-500);
  --surface-elevation-6: var(--color-dark-mode-surface-500);
  --surface-elevation-7: var(--color-neutral-gray-100);
  --surface-elevation-modal: var(--color-neutral-white);

  --gradients-primary-orange-gradient: linear-gradient(
    180deg,
    var(--color-orange-500) 0%,
    var(--color-orange-700) 100%
  );
  --gradients-primary-orange-gradient-buttons: linear-gradient(
    135deg,
    var(--color-orange-500) 0%,
    var(--color-orange-600) 100%
  );
  --gradients-primary-gold-gradient: linear-gradient(
    165deg,
    var(--color-orange-500) -5.61%,
    #ffe266 40.74%,
    var(--color-orange-500) 97.23%
  );
  --gradients-secundary-orange-gradient: linear-gradient(
    180deg,
    rgba(243, 144, 21, 0.7) 0%,
    rgba(240, 79, 27, 0.7) 100%
  );
  --gradients-gradient-for-supports-and-tabs: linear-gradient(
    180deg,
    rgba(255, 125, 38, 0) 0%,
    rgba(255, 125, 38, 0.1) 100%
  );
  --gradients-gradient-for-cards: linear-gradient(
    135deg,
    #ff7d26 0%,
    #ffd7bc 100%
  );

  //  Material theming
  --mdc-theme-error: var(--status-error);
}

[color-scheme="dark"] {
  //  Surfaces - Dark mode
  --surface-elevation-modal: var(--color-dark-mode-surface-300);
  --surface-elevation-1: var(--color-dark-mode-surface-100);
  --surface-elevation-2: var(--color-dark-mode-surface-400);
  --surface-elevation-3: var(--color-dark-mode-surface-500);
  --surface-elevation-4: var(--color-dark-mode-surface-400);
  --surface-elevation-5: var(--color-dark-mode-surface-500);
  --surface-elevation-6: var(--color-neutral-white);
  --surface-elevation-7: var(--color-dark-mode-surface-400);

  --surface-visa-blue: #5699fd;
  --surface-echeck-green: #46bd57;
  --surface-echeck-blue: #229ccf;
  --surface-bluer-effect: #161819;
  --surface-background: var(--color-neutral-black);
  --surface-active: var(--color-orange-400);
  --surface-inactive: var(--color-dark-mode-surface-400);
  --surface-graphic-1: var(--color-blue-gray-300);
  --surface-graphic-2: var(--color-blue-gray-200);
  --surface-footer: var(--surface-elevation-modal);

  //  Text & Icons - Dark mode
  --status-completed: var(--color-dark-mode-blue-completed);
  --status-error: var(--color-dark-mode-red-error);
  --status-expired: var(--color-neutral-gray-300);
  --status-successful: var(--color-dark-mode-green-successful);
  --status-warning: var(--color-dark-mode-yellow-warning);
  --thumb-primary: var(--color-neutral-gray-500);
  --text-limit: var(--color-neutral-gray-400);
  --text-invert: var(--color-neutral-gray-500);
  --text-highlighted: var(--color-orange-400);
  --text-highlighted-invert: var(--color-orange-500);
  --text-breadcrumbs-active: var(--color-orange-400);
  --icon-primary: var(--color-neutral-white);
  --icon-secondary: var(--color-neutral-gray-200);
  --icon-tertiary: var(--color-neutral-gray-300);
  --icon-limit: var(--color-neutral-gray-400);
  --icon-invert: var(--color-neutral-gray-400);

  //Borders - Dark mode
  --border-elevation-avatar: var(--color-dark-mode-surface-400);
  --border-elevation-1: var(--color-dark-mode-stroke-surface-100);
  --border-elevation-4: var(--color-dark-mode-stroke-surface-400);
  --border-field-default: var(--color-neutral-gray-400);
  --border-field-inactive: var(--color-neutral-gray-500);
  --border-field-active: var(--color-orange-400);
  --border-elevation-modals: var(--color-dark-mode-stroke-surface-300);
  --gradients-gradient-for-supports-and-tabs: linear-gradient(
    180deg,
    rgba(34, 41, 44, 0) 0%,
    #22292c 100%
  );
  --gradients-gradient-for-cards: linear-gradient(
    135deg,
    #37474f 100%,
    #37474f 0%
  );
}
