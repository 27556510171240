// override dialog styles for full screen
.mat-mdc-menu-panel.mdc-menu-surface {
  will-change: unset;
}

.mat-mdc-menu-item:not([disabled]):hover {
  --mat-menu-item-hover-state-layer-color: var(--surface-elevation-2);
}

body .cdk-overlay-container {
  z-index: 100000;
}
.cdk-overlay-container,
mat-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 24px;
  --mdc-dialog-supporting-text-color: var(--text-primary);
  --mdc-dialog-subhead-color: var(--text-primary);
}

.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      padding: 24px 32px;
      --mdc-dialog-container-elevation: 0px 4px 8px 0px var(--color-box-shadow);
      --mdc-dialog-container-color: var(--surface-elevation-modal);
      max-width: 575px;
    }
  }

  .mdc-dialog__actions {
    padding: 0;
  }

  &.mdc-dialog .mdc-dialog__content {
    padding: 0;
  }
}
.mat-mdc-dialog-surface.mdc-dialog__surface p {
  padding: 0;
  text-align: center;
  color: var(--text-primary);
}

.mat-mdc-dialog-title.fz-dialog {
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--icon-primary);
    padding: 0;
    z-index: 1000;
    &::before {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mdc-dialog__surface {
        padding: 40px 64px;
      }
    }
  }
}
