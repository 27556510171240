.processing {
  position: relative;

  &[disabled] {
    background: var(--surface-background, grey);
  }

  &--hide-content {
    span:not(.dot) {
      opacity: 0;
    }
  }

  &__root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    gap: 8px;

    .dot {
      animation-name: processing;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      height: 6px;
      width: 6px;
      display: block;
      background: var(--primary-color, orange);
      border-radius: 50%;
      border: 1px solid #fff;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }

    &--center-center {
      align-items: center;
      justify-content: center;
    }

    &--bottom-center {
      align-items: end;
      justify-content: center;
      bottom: 4px;
      top: initial;
    }
  }
}

@keyframes processing {
  from {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
