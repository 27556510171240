.mat-mdc-form-field-infix {
  width: 100% !important;
}

.mat-mdc-form-field {
  width: 100%;
  &.mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      margin: 0;
      .mat-mdc-form-field-flex {
        height: 42px;
        align-items: center;

        .mat-mdc-form-field-icon-suffix {
          color: var(--icon-limit);
          margin-right: 16px;
        }
      }
    }
  }

  .mdc-floating-label--required:not(
      .mdc-floating-label--hide-required-marker
    )::after {
    margin-left: 4px;
  }
}

.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field {
  width: 100%;
}
