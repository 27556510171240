// google autocomplete address
.pac-container {
  z-index: 9999999;
  border-radius: 8px;
  width: auto !important;
  box-shadow: 1px 0px 20px -10px #0000004a;
  border: none;
  outline: 1px solid var(--sys-outline);

  &.position-fixed {
    position: fixed !important;
  }

  .pac-item {
    padding: 12px 16px;
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
    font: var(--sys-body-medium);

    &:hover {
      cursor: pointer;
    }

    .pac-icon {
      display: none;
    }
  }

  &.pac-logo::after {
    display: none;
  }
}
