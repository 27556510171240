a {
  text-decoration: none;

  &[href] {
    color: var(--sys-primary);
  }

  &.link {
    text-decoration: underline;
    color: var(--sys-primary);
  }
}
