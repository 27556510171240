:root {
  --primary-color: var(--sys-primary);
  --text-highlighted: var(--sys-primary);
  --border-field-default: var(--color-neutral-gray-400);
  --border-field-active: var(--color-orange-400);
  --border-elevation-1: #6f80877f;
  --primary-border-color: #e0e0e0;
  --text-primary: var(--mat-app-text-color);
  --breadcrumb-height: 36px;
  --warn-color: var(--color-status-red-error);
}

// hide Google reCaptcha and show only in specific places
body .grecaptcha-badge {
  visibility: hidden;
}

body.fz-auth .grecaptcha-badge {
  visibility: visible;
}

table div.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  animation: placeHolderShimmer 1.2s linear infinite;

  &::before {
    content: "";
    display: block;
    background: var(--color-neutral-gray-400);
    border-radius: 12px;
    width: 96%;
    height: 8px;
  }

  &::after {
    content: "";
    display: block;
    background: var(--color-neutral-gray-400);
    border-radius: 12px;
    width: 85%;
    height: 8px;
  }
}

// animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.animated-number-change {
  transition: --num 2s;
  counter-set: num var(--num);
  &::after {
    content: counter(num);
  }
}

.hide-in-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}

fieldset {
  border-radius: 12px;

  legend {
    padding-inline: 8px;

    &:has(mat-icon) {
      padding-inline-end: 32px;
    }

    label {
      mat-icon {
        position: absolute;
      }
    }
  }
}
