html .mat-body-large p,
html .mat-typography .mat-body-large p,
html .mat-headline-small,
html .mat-typography .mat-headline-small,
html .mat-typography h6 {
  margin: 0;
  padding: 0;
}

// override links styles
a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

// Remove autofill styles from inputs
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }

  &:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: light-dark(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ) !important;
    color: inherit !important;
  }

  &:-webkit-autofill {
    border: none;
    border-radius: 0.3rem;
    caret-color: var(--sys-text);
    background: transparent;
    -webkit-text-fill-color: var(--sys-on-primary);
    -webkit-background-clip: text;
    box-shadow: 0 0 0 50px #00000000 inset;
  }
}
