:root {
  --fz-container-margin: 16px;
}

.container,
.mat-toolbar-row.container {
  box-sizing: border-box;
  margin: 0px auto;
  width: calc(100% - 2 * var(--fz-container-margin));
}

.bottom-spacer {
  margin-bottom: 32px;
}

.wallet-view {
  .details-column,
  .principal-column {
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  :root {
    --fz-container-margin: 48px;
  }

  .wallet-view {
    .wallet-container {
      display: flex;
      flex-flow: row;
      place-content: space-between;
      gap: 32px;
      justify-content: center;
    }

    .details-column {
      flex: 1 1 34%;
      box-sizing: border-box;
      max-width: 34%;
      order: 2;
      .big {
        flex: 1 1 48%;
        max-width: 48%;
      }
    }
    .principal-column {
      flex: 1 1 66%;
      box-sizing: border-box;
      max-width: 66%;
      order: 1;
      .short {
        flex: 1 1 62%;
        max-width: 62%;
      }
    }
  }
}

@media (min-width: 1440px) {
  :root {
    --fz-container-margin: 0;
  }

  .container,
  .mat-toolbar-row.container {
    max-width: 1440px;
  }
}

@media (min-width: 1520px) {
  .container,
  .mat-toolbar-row.container {
    max-width: 100%;
    width: calc(100% - 208px);
    margin: auto;
  }
}

@media (min-width: 1920px) {
  .container,
  .mat-toolbar-row.container {
    max-width: 1920px;
    width: calc(100% - 208px);
    margin: auto;
  }
}

@media (max-width: 768px) {
  .hide--phone-device {
    display: none;
  }
}

// Global utils class
.display {
  &--flex {
    display: flex;
  }
  &--block {
    display: block;
  }
}

.flex-direction {
  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }
}

.justify-content {
  &--center {
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }
}

.align-items {
  &--center {
    align-items: center;
  }

  &--flex-start {
    align-items: flex-start;
  }

  &--flex-end {
    align-items: flex-end;
  }
}

/*
 * View Transitions
 */
@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}
