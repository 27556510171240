/**
 * This code represents a selection of SCSS code.
 * It is used for [describe purpose or functionality].
 *
 * [Add any additional details or explanations here]
 */

//  Color palette for the application
$color-blue-gray-blue-gray-100: #6f8087ff;
$color-blue-gray-200: #455a64ff;
$color-blue-gray-300: #37474fff;
$color-blue-gray-400: #283236ff;

$color-dark-mode-blue-completed: #59b5ebff;
$color-dark-mode-surface-100: #161819ff;
$color-dark-mode-surface-300: #1c2022ff;
$color-dark-mode-surface-400: #22292cff;
$color-dark-mode-surface-500: #283236ff;
$color-dark-mode-green-successful: #52be80ff;
$color-dark-mode-red-error: #ff6666ff;
$color-dark-mode-stroke-surface-100: #6f80877f;
$color-dark-mode-stroke-surface-300: #6f80877f;
$color-dark-mode-stroke-surface-400: #6f808799;
$color-dark-mode-yellow-warning: #ffd67bff;

$color-neutral-gray-100: #f5f5f5ff;
$color-neutral-gray-200: #ddddddff;
$color-neutral-gray-300: #c4c4c4ff;
$color-neutral-gray-400: #9a9a9aff;
$color-neutral-gray-500: #4f4f4fff;
$color-neutral-black: #121212ff;
$color-neutral-white: #ffffffff;

$color-orange-100: #fef5edff;
$color-orange-200: #ffd7bcff;
$color-orange-300: #ffb88aff;
$color-orange-400: #ff9b58;
$color-orange-500: #ff7d26ff;
$color-orange-600: #f04e1bff;
$color-orange-700: #f04f1b;

$color-status-blue-completed: #30a3e6ff;
$color-status-blue-completed-10: #30a3e619;
$color-status-gray-expired-10: #9a9a9a19;
$color-status-green-successful: #27ae60ff;
$color-status-green-successful-10: #27ae6019;
$color-status-red-error: #ff3838ff;
$color-status-red-error-10: #ff383819;
$color-status-yellow-warning: #ffcc5aff;
$color-status-yellow-warning-10: #ffcc5a19;

$color-vip-gold-100: #eddfc2ff;
$color-vip-gold-200: #b59149ff;
$color-vip-gold-300: #d2b478ff;
$color-vip-gold-400: #b48e44ff;

$surface-bluer-effect: #ffffffe5;
$surface-echeck-blue: #006f9dff;
$surface-echeck-green: #2ea53fff;
$surface-visa-blue: #0052ccff;
$color-box-shadow: rgba(18, 18, 18, 0.12);

.dark {
  .mat-menu-panel {
    background: #000;
  }
}
